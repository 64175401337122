import { Pipe, PipeTransform } from '@angular/core';
import { Breadcrumb } from '@agilox/ui-common';

@Pipe({
	name: 'breadcrumbEllipsis',
	standalone: true,
})
export class BreadcrumbEllipsisPipe implements PipeTransform {
	/**
	 * @param arr
	 * @param maxDisplay
	 * @param expanded
	 *
	 * Used to implement the ellipsis functionality based on following conditions:
	 * if the amount of breadcrumbs is higher than the maxBreadcrumbs
	 * only the following breadcrumbs will be displayed:
	 * 1. first breadcrumb
	 * 2. breadcrumbs in between the range of maxBreadcrumbs to length of the array of breadcrumbs
	 *
	 * @default 4
	 * @min 2
	 * @example maxBreadcrumbs = 4
	 * breadcrumbs = [1,2,3,4,5,6,7,8,9,10]
	 * following breadcrumbs will be displayed:
	 * 1, ... , 8, 9, 10
	 */
	transform(arr: Array<Breadcrumb>, maxDisplay: number, expanded?: boolean): any[] {
		if (arr.length <= maxDisplay || maxDisplay < 1 || expanded) {
			return arr;
		}
		if (maxDisplay === 1) {
			let retArray: any[] = [];
			arr.forEach((item, index) => {
				if (index < arr.length - 1) {
					retArray.push('...');
				} else {
					retArray.push(item);
				}
			});
			return retArray;
		} else {
			return [...arr.slice(0, 1), '...', ...arr.slice(arr.length - maxDisplay + 1)];
		}
	}
}
