import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@academy/env/environment';
import { Observable } from 'rxjs';

/**
 * exceptions for content type,
 * where the content type should stay as application/json
 * Needed in cases where we directly call the agilox api and not the contao api
 */
const contentTypeExceptions = ['/v2/user/listNotifications'];

/**
 * setting the credentials
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.includes('wistia.com')) {
			return next.handle(req);
		}

		const headers = new HttpHeaders({
			'Content-Type': this.getContentType(req.url),
		});

		return next.handle(
			req.clone({
				headers: headers,
				withCredentials: true,
			})
		);
	}

	private getContentType(url: string) {
		const exception = contentTypeExceptions.find((exception) => url.includes(exception));
		if (!exception && (environment.name === 'local' || environment.name === 'production')) {
			return 'text/plain';
		} else {
			return 'application/json';
		}
	}
}
