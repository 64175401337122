@let offcanvasOpen = (offcanvasOpen$ | async) || false;
<ui-offcanvas
	[header]="'notifications.title' | translate"
	[open]="offcanvasOpen"
	[closeOnOutsideClick]="true"
	(closed)="closeOffcanvas()"
>
	@let notifications = notifications$ | async;
	@let notificationsLength = notifications?.length || 0;
	@let hasUnreadNotifications = (hasUnreadNotifications$ | async) || false;
	@let loading = (initialLoading$ | async) ?? true;

	@if (notifications && offcanvasOpen && !loading) {
		<div
			class="flex flex-col max-h-full overflow-hidden"
			[ngClass]="notificationsLength ? 'h-full' : 'h-screen justify-center'"
			data-cy="user-notifications-offcanvas"
		>
			<div class="flex-grow overflow-auto pb-4 md:pb-8" #articles [hidden]="!notificationsLength">
				@for (notification of notifications; track notification.id; let idx = $index) {
					<ui-user-notification-item
						[notification]="notification"
						[isExpanded]="(idx === 0 && !hasUnreadNotifications) || !notification.read"
					></ui-user-notification-item>
					@if (idx < notifications.length - 1) {
						<ui-divider class="c-user-notifications__divider block py-3"></ui-divider>
					}
				}
			</div>

			@if (notificationsLength === 0) {
				<div class="flex flex-col justify-center">
					<img
						src="assets/images/illu_notifications_empty.svg"
						alt="No notifications"
						class="mx-auto my-8"
					/>

					<ui-headline type="h4" class="text-center">
						{{ 'notifications.no_notifications' | translate }}
					</ui-headline>
				</div>
			}
		</div>
	} @else {
		<ui-spinner></ui-spinner>
	}
	<div offcanvas-buttons>
		<ui-button
			[hidden]="loading"
			[text]="'general.close' | translate"
			[fullWidth]="true"
			(buttonClicked)="closeOffcanvas()"
		></ui-button>
	</div>
</ui-offcanvas>
