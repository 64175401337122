import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec, Meta } from '@agilox/common';
import { Sort } from '@angular/material/sort';

/**
 * Builds HTTP parameters for API requests with custom encoding.
 */
export class HttpParamsBuilder {
	static buildParameters(page?: Meta, sort?: Sort, search?: string, filter?: object): HttpParams {
		let stringParam = '';

		if (search) {
			stringParam += `search=${search}`;
		}
		if (page) {
			stringParam += this.objectToParams(page, 'page');
		}
		if (sort) {
			stringParam += this.toSortParams(sort);
		}
		if (filter) {
			stringParam += this.objectToParams(filter, 'filter');
		}

		return new HttpParams({
			fromString: stringParam,
			encoder: new CustomHttpUrlEncodingCodec(),
		});
	}

	private static toSortParams(sort: Sort): string {
		if (sort.direction == '') {
			return '';
		}
		const direction = sort.direction === 'desc' ? '-' : '';
		return `&sort=${direction}${sort.active}`;
	}

	private static objectToParams(data: object, key: string): string {
		let tempString = '';
		Object.entries(data).forEach(([propertyKey, value]) => {
			if (value && !Array.isArray(value)) {
				tempString += `&${key}[${propertyKey}]=${value}`;
			}
		});
		return tempString;
	}
	private static arrayToParams(data: any[], key: string): string {
		return `&${key}${data.join(',')}`;
	}
}
