<div class="ui-select-search">
	<input
		[attr.data-cy]="'ui-select-search'"
		type="text"
		class="ui-select-search__input"
		[placeholder]="placeholder"
		[formControl]="formControl"
		#searchInput
	/>
	@if (!formControl.value) {
		<ui-icon icon="lens_search" size="m"> </ui-icon>
	} @else {
		<ui-icon
			size="m"
			icon="x_cross"
			(iconClicked)="clearSearch()"
			class="ui-select-search__clearIcon"
			[action]="true"
		>
		</ui-icon>
	}
</div>
