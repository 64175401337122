import {
	Directive,
	ElementRef,
	Inject,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
	selector: '[uiBadge]',
	host: {
		class: 'relative',
	},
	standalone: true,
})
export class BadgeDirective implements OnInit, OnChanges, OnDestroy {
	@Inject(DOCUMENT) private document: Document = inject(DOCUMENT);
	private el: ElementRef = inject(ElementRef);

	@Input('uiBadge') badgeText: string = '';
	@Input() styleClasses: string[] = [];

	private badge: HTMLElement | null = null;

	private classes: string[] = [
		'absolute',
		'-top-1',
		'-right-1',
		'rounded-full',
		'bg-error',
		'text-white',
		'text-[10px]',
		'font-semibold',
		'px-1.5',
		'h-4',
		'flex',
		'items-center',
	];

	ngOnInit() {
		this.createBadge();
	}

	private createBadge() {
		if (!this.badgeText) {
			return;
		}

		this.badge = this.document.createElement('span');
		this.badge.innerText = this.badgeText;

		this.badge.classList.add(...this.classes, ...this.styleClasses);

		this.el.nativeElement.appendChild(this.badge);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['badgeText'] && this.badge) {
			if (!changes['badgeText'].currentValue) {
				this.destroyBadge();
				return;
			}
			this.badge.innerText = changes['badgeText'].currentValue;
		} else {
			this.createBadge();
		}
	}

	ngOnDestroy() {
		this.destroyBadge();
	}

	private destroyBadge() {
		if (this.badge) {
			this.el.nativeElement.removeChild(this.badge);
			this.badge = null;
		}
	}
}
