import { App, AppMenuPoints, RedirectService } from '@agilox/common';
import { AppMenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';

@Component({
	selector: 'ui-mobile-app-nav',
	templateUrl: './mobile-app-nav.component.html',
	styleUrls: ['./mobile-app-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAppNavComponent implements OnInit {
	@Input() set apps(apps: Array<AppMenuPoint> | undefined) {
		if (!apps) {
			apps = AppMenuPoints;
		}

		this.myApp = apps.find((app) => app.name === 'my') ?? AppMenuPoints[0];
		this._apps = apps.filter((app) => app.name !== 'my');
	}
	private _apps: Array<AppMenuPoint> = [];
	get apps(): Array<AppMenuPoint> {
		return this._apps;
	}
	@Input() activeApp: AppMenuPoint | undefined;
	@Input() environment: string = 'live';
	@Input() navColor: NavColor = NavColors.Dark;
	@Input() open: boolean = false;

	@Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

	myApp: AppMenuPoint = AppMenuPoints[0];

	private redirectService: RedirectService = inject(RedirectService);

	toggle() {
		this.open = !this.open;
		this.toggled.emit(this.open);
	}

	@HostListener('window: click', ['$event'])
	@HostListener('window:keydown.escape', ['$event'])
	clickOutside(event?: MouseEvent | KeyboardEvent) {
		const target = event?.target as HTMLElement;
		const hasAppNavClass = target?.closest('.ui-mobile-app-nav');
		if (!hasAppNavClass && this.open) {
			this.open = false;
		}
	}

	ngOnInit() {
		this.setUrls(this.apps);
		this.setUrls([this.myApp]);
	}

	private setUrls(apps: AppMenuPoint[]) {
		apps.forEach((app) => {
			app.url = this.redirectService.getRedirectAppUrl(app.name, this.environment);
		});
	}
}
