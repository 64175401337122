import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
	name: 'pluralTranslate',
	standalone: true,
})
export class PluralTranslatePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(number: number, singularText: string, pluralText: string, textParams?: Object): string {
		return this.translate.instant(
			number > 1 || number === 0 ? pluralText : singularText,
			textParams
		);
	}
}
