import { IconType, NavColor, Size, Sizes } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'ui-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
	@Input() size: Size = Sizes.S;
	@Input() type: IconType | NavColor | undefined;
	@Input() icon: string | undefined;
	@Input() action: boolean | undefined;
	@Input() disabled: boolean = false;

	@Output() iconClicked: EventEmitter<Event> = new EventEmitter<Event>();

	@HostBinding('class') get classes() {
		return `ui-icon ui-icon--${this.size} ${this.type ? 'ui-icon--' + this.type : ''} ${
			this.action && !this.disabled ? 'ui-icon--action' : ''
		}
		${this.disabled ? 'ui-icon--disabled' : ''}`;
	}

	/**
	 * gets invoked when icon is clicked
	 * @param event mouse click event
	 */
	clickedOnIcon(event: Event) {
		// prevents the event when icon is disabled
		if (this.disabled) {
			event.preventDefault();
			event.stopImmediatePropagation();
			event.stopPropagation();
		} else {
			this.iconClicked.emit(event);
		}
	}
}
