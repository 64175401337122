import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconModule } from '@agilox/ui';
import { NgClass } from '@angular/common';

@Component({
	selector: 'ui-toggleable-filter-button',
	templateUrl: './toggleable-filter-button.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IconModule, NgClass],
})
export class ToggleableFilterButtonComponent {
	@Input() text: string = '';
	@Input() isToggled: boolean = false;
	@Output() toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	public toggle(): void {
		this.isToggled = !this.isToggled;
		this.toggleEvent.emit(this.isToggled);
	}
}
