import { SelectOption } from '@agilox/ui-common';
import { Injectable } from '@angular/core';
import { countries } from '../constants/countries.const';
import { Country } from '../interfaces/country.interface';

@Injectable({
	providedIn: 'root',
})
export class CountriesService {
	countriesForSelect: Array<SelectOption<Country>> = [];

	constructor() {
		this.countriesForSelect = countries.map((country) => {
			return { title: country.name, value: country };
		});
	}

	getAllCountries() {
		return countries;
	}

	/**
	 * returns the according country to each code
	 * @param code countrycode
	 */
	getCountryByCountryCode(code: string): Country {
		for (const country of countries) {
			if (country.code === code) {
				return country;
			}
		}
		return { name: '', code: '' };
	}

	/**
	 * returns the according country to each name
	 * @param code countrycode
	 */
	getCountryByCountryName(name: string): Country {
		for (const country of countries) {
			if (country.name === name) {
				return country;
			}
		}
		return { name: '', code: '' };
	}
}
