<div
	class="ui-input ui-input--{{ type }}"
	[ngClass]="{
		'ui-input--error': formControl.invalid && formControl.value,
		'ui-input--valid': !focused && formControl.valid && showWhenValid && formControl.value,
		'ui-input--disabled': formControl.disabled,
	}"
>

	<div class="ui-input__inputWrapper ui-input__inputWrapper--{{ type }}">
		@if (type === 'number') {
			<ui-button
				icon="x_arrow_left"
				size="l"
				type="secondary"
				[type]="formControl.errors?.['min'] ? 'primary' : 'secondary'"
				(buttonClicked)="onNumberButtonClick('down')"
				[disabled]="formControl.errors?.['min']"
			></ui-button>
		}

		<div
			class="ui-input__input {{ styleClass }}"
			[ngClass]="{ 'is-focused': focused && !formControl.invalid }"
		>
			<input
				[type]="type === 'password' && passwordVisible ? 'text' : type === 'search' ? 'text' : type"
				class="ui-input__inputField ui-input__inputField--{{ type }}"
				[formControl]="formControl"
				[placeholder]="placeholder"
				(focusout)="onFocusOut()"
				(focusin)="focused = true"
				[id]="inputId"
				data-cy="ui-input-value"
				#inputElement
			/>
			<div class="ui-input__icons">
				@if (type === 'search') {
					<!-- Search Icon when the input is a search input -->
					<ui-icon icon="lens_search" class="ui-input__icon" size="s"> </ui-icon>
				} @else if (type === 'password') {
					<ui-icon
						[icon]="passwordVisible ? 'visibility_off' : 'visibility_on'"
						class="ui-input__icon"
						size="s"
						(click)="toggleVisibility()"
					>
					</ui-icon>
				} @else if (type !== 'number') {
					<!-- Icon to clear the input -->
					@if (
						formControl.valid &&
						((showWhenValid && formControl.value) || !showWhenValid) &&
						!hideClearIcon &&
						focused
					) {
						<ui-icon
							icon="x_cross"
							class="ui-input__icon"
							size="s"
							(mousedown)="clearInput($event)"
						></ui-icon>
					}

					<!-- Failure Icon | When the input is not valid -->
					@if (formControl.invalid && formControl.value) {
						<ui-icon icon="failures" type="error" class="ui-input__icon" size="s"> </ui-icon>
					}

					<!-- Success Icon | When the input is valid -->
					@if (!focused && formControl.valid && showWhenValid && formControl.value) {
						<ui-icon icon="x_check" type="success" class="ui-input__icon" size="s"> </ui-icon>
					}
				}
			</div>
		</div>

		@if (type === 'number') {
			<ui-button
				icon="x_arrow_right"
				size="l"
				[type]="formControl.errors?.['max'] ? 'primary' : 'secondary'"
				[disabled]="formControl.errors?.['max']"
				(buttonClicked)="onNumberButtonClick('up')"
			></ui-button>
		}
	</div>
</div>
