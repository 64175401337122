import { Inject, Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { User } from '@agilox/common';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsService {
	private roleChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

	user: User | undefined;
	onRoleChange$: Observable<string> = this.roleChange.asObservable();

	private translateService: TranslateService = inject(TranslateService);

	/**
	 * sets data from response
	 * @param data response data
	 */
	setFromResponse(data: any): void {
		this.user = data.userProfile;
		if (this.user) {
			this.roleChange.next(this.user.role);
			this.setLanguage();
		}
	}

	/**
	 * sets the user language
	 */
	setLanguage() {
		if (this.user && this.user.lang) {
			this.translateService.setDefaultLang(this.user.lang);
			this.translateService.use(this.user.lang);
		} else if (!this.user) {
			const browserLang = navigator.language || window.navigator.language;
			const defaultLanguage = browserLang.includes('de') ? 'de' : 'en';
			this.translateService.use(defaultLanguage);
		} else {
			this.translateService.setDefaultLang('en');
			this.translateService.use('en');
			this.user.lang = 'en';
		}
	}

	clearLocalStorage() {
		localStorage.clear();
	}
}
