import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'ui-empty-state',
	standalone: true,
	imports: [ButtonComponent, TranslateModule],
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
	@Input() image: string = '';
	@Input() text: string = '';
	@Input() buttonText: string = '';
	@Input() buttonIcon: string = '';
	@Input() isAbsolute: boolean = false;
	@Output() buttonClicked: EventEmitter<Event> = new EventEmitter<Event>();

	clicked(event: Event) {
		this.buttonClicked.emit(event);
	}
}
