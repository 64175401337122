<div
	[class.ui-button--right]="rightAligned"
	(mouseenter)="onHover(true)"
	(mouseleave)="onHover(false)"
>
	<button
		class="ui-button ui-button--{{ type }} ui-button--{{ size }}"
		(click)="clicked($event)"
		[class.is-disabled]="disabled"
		[class.is-inverted]="inverted"
		[class.ui-button--fullWidth]="fullWidth"
		[class.ui-button--fullWidthMobile]="fullWidthMobile"
		[class.ui-button--onlyIcon]="icon && !text"
		[ngStyle]="colorStyles"
	>
		@if (icon) {
			<div
				class="ui-button__icon"
				[class.ui-button__icon--hidden]="showSpinner"
				[class.ui-button__icon--withoutTextMobile]="hideTextOnMobile"
			>
				<ui-icon [icon]="icon" class="ui-button__icon" [size]="size"></ui-icon>
			</div>
		}

		<span
			class="ui-button__text"
			[class.ui-button__text--hidden]="showSpinner"
			[class.ui-button__text--hiddenMobile]="hideTextOnMobile"
			[class.ui-button__text--withIcon]="text && icon"
			>{{ text }}</span
		>

		@if (showSpinner) {
			<ui-spinner
				class="ui-button__spinner"
				[small]="true"
				[light]="type === 'primary' || type === 'dark' || type === 'blue'"
				[color]="type === 'dark' || type === 'blue' ? 'secondary' : 'primary'"
			></ui-spinner>
		}
	</button>
</div>
