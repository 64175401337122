import { Role } from '@agilox/common';
import { MenuPoint } from '@agilox/ui-common';
import { MenuPointUrl } from '../enums/menupoint-url.enum';

/** holds all menupoints */
export const academyMenuPoints = {
	dashboard: {
		name: 'menu.dashboard',
		url: MenuPointUrl.dashboard,
		icon: 'dashboard',
		roles: [],
	} as MenuPoint,
	trainings: {
		name: 'menu.trainings',
		url: MenuPointUrl.trainings,
		icon: 'academy',
		roles: [],
	} as MenuPoint,
	certificates: {
		name: 'menu.certificates',
		url: MenuPointUrl.certificates,
		icon: 'certificate',
		roles: [],
	} as MenuPoint,
	media: {
		name: 'menu.media',
		url: MenuPointUrl.media,
		icon: 'media',
		roles: [],
	} as MenuPoint,
	archive: {
		name: 'menu.archive',
		url: MenuPointUrl.archive,
		icon: 'archive',
		roles: [],
	} as MenuPoint,
	help: {
		name: 'menu.help',
		url: '',
		icon: 'o_quesmark',
		roles: [Role.superuser, Role.customer, Role.partner],
		position: 'bottom',
		callback: true,
	} as MenuPoint,
	logout: {
		name: 'general.logout',
		url: '',
		icon: 'logout',
		roles: [],
		position: 'bottom',
		callback: true,
	} as MenuPoint,
};
