import { animate, state, style, transition, trigger } from '@angular/animations';

export const dropdownAnimation = trigger('dropdownAnimation', [
	state(
		'closed',
		style({
			height: '0',
			opacity: 0,
		})
	),
	state(
		'open',
		style({
			height: '*',
			opacity: 1,
		})
	),
	transition('closed <=> open', [animate('300ms ease-in-out')]),
]);
