import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SelectInputTextPipe } from './pipes';
import { SelectCustomOptionDirective } from './directives';
import {
	SelectOptionComponent,
	SelectInputComponent,
	SelectSearchComponent,
	SelectButtonComponent,
	SelectOptionListComponent,
} from './components';
import { SelectComponent } from './select.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { InputModule } from '../input/input.module';
import { IconModule } from '../icon/icon.module';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ButtonComponent } from '../button/button.component';
import { NotifyComponent } from '../notify/notify.component';
import { PillComponent } from '../pill/pill.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@NgModule({
	declarations: [
		SelectComponent,
		SelectOptionListComponent,
		SelectOptionComponent,
		SelectInputComponent,
		SelectSearchComponent,
		SelectButtonComponent,
		SelectInputTextPipe,
		SelectCustomOptionDirective,
	],
	exports: [SelectComponent, SelectCustomOptionDirective],
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		DropdownModule,
		InputModule,
		IconModule,
		PillComponent,
		CheckboxComponent,
		TooltipModule,
		ButtonComponent,
		NotifyComponent,
		SpinnerComponent,
	],
})
export class SelectModule {}
