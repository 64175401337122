<div class="ui-nav">
	<div class="ui-nav__main">
		@if (appNavVisible) {
			<ui-app-nav
				[apps]="apps"
				[activeApp]="activeApp"
				[environment]="environment"
				[navColor]="navColor"
			></ui-app-nav>
		} @else if (logoPath) {
			<img class="ui-nav__logo" [src]="logoPath" alt="App Logo" />
		}
		<ui-nav-list
			[items]="items"
			[navColor]="navColor"
			[openItem]="openedMenuPoint"
			[beta]="beta"
			(menuPointAction)="onMenuPointAction($event)"
			(closeMenu)="closeSubMenu()"
			class="ui-nav__list"
		></ui-nav-list>
	</div>
	<div class="ui-nav__subNav" *ngIf="openedMenuPoint && open">
		<ui-sub-nav
			[menuItem]="openedMenuPoint"
			[navColor]="navColor"
			(itemClick)="onMenuPointAction($event)"
			(mouseleave)="onMouseLeave()"
		>
		</ui-sub-nav>
	</div>
</div>
