import {
	CheckSubscriptionExpireSoonPipe,
	getSubscriptionRemaining,
	PluralTranslatePipe,
	Role,
	Vehicle,
} from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';
import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { IconModule } from '../icon/icon.module';
import { PillComponent } from '../pill/pill.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { VehicleOptionTooltipPipe } from './pipes/vehicle-option-tooltip.pipe';
import { PipeEmptyModule } from '@agilox/pipe/empty';

@Component({
	selector: 'ui-vehicle-select-option',
	templateUrl: './vehicle-select-option.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CheckboxComponent,
		IconModule,
		TranslateModule,
		TooltipModule,
		PillComponent,
		NgClass,
		CheckSubscriptionExpireSoonPipe,
		DatePipe,
		VehicleOptionTooltipPipe,
		PluralTranslatePipe,
		PipeEmptyModule,
	],
})
export class VehicleSelectOptionComponent {
	@Input() option!: SelectOption<Vehicle>;
	@Input() selected: boolean = false;
	@Input() displayPills!: boolean;
	@Input() unionSelected!: boolean;
	@Input() isDisabled!: boolean;
	@Input() role: Role = Role.support;

	@Output() toggleItem: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
	@Output() selectWholeUnion: EventEmitter<void> = new EventEmitter<void>();

	toggleItemHandler(option: SelectOption<Vehicle>): void {
		if (!option.isDisabled) {
			this.toggleItem.emit(option.value);
		}
	}

	onSelectWholeUnion(option: SelectOption<Vehicle>, event: MouseEvent) {
		event.stopImmediatePropagation();
		event.preventDefault();
		event.stopPropagation();
		if (!option.isDisabled) {
			this.selectWholeUnion.emit();
		}
	}

	protected readonly Role = Role;
	protected readonly getSubscriptionRemaining = getSubscriptionRemaining;
}
