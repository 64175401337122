export enum AcademyColors {
	PRIMARY = '#859BA9',
	PRIMARY900 = '#526D7E',
	PRIMARY800 = '#6B8494',
	PRIMARY700 = '#859BA9',
	PRIMARY600 = '#9EB2BE',
	PRIMARY500 = '#B7C9D3',
	PRIMARY400 = '#CADAE2',
	PRIMARY300 = '#DBE7EC',
	PRIMARY200 = '#E8F0F5',
	PRIMARY100 = '#F3F9FB',
}
