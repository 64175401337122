import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from '../services/error.service';
import { ErrorType } from '../enums/error-type.enum';

/**
 * handles errors when the loading of a chunk failed
 */
@Injectable()
export class ChunkLoadingFailedErrorHandler implements ErrorHandler {
	constructor(private errorService: ErrorService) {}

	/**
	 * gets invoked when an error gets throws
	 * invokes the chunkLoadingFailedService
	 * @param error
	 */
	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk .* failed/;

		/**
		 * This is a workaround for the issue that the error message is not always the same.
		 */
		const jsFileNotFoundMessage = /.*\.js.*404/;

		if (
			error &&
			(chunkFailedMessage.test(error.message) || jsFileNotFoundMessage.test(error.message))
		) {
			this.errorService.setError({ type: ErrorType.CHUNK_ERROR });
		} else {
			throw error;
		}
	}
}
