<div class="h-10 w-10" data-cy="ui-download-icon">
	@switch (status) {
		@case (DownloadStatus.SUCCESS) {
			<ui-button
				icon="x_check"
				[type]="type"
				[uiTooltip]="'download.success' | translate"
			></ui-button>
		}
		@case (DownloadStatus.ERROR) {
			<ui-button
				icon="failures"
				[type]="type"
				[uiTooltip]="'download.error' | translate"
			></ui-button>
		}
		@default {
			@let isPending = status === DownloadStatus.PENDING;
			<ui-button
				icon="download"
				[type]="type"
				[uiTooltip]="(isPending ? 'download.pending' : (iconTooltip ?? '')) | translate"
				(buttonClicked)="iconClicked.emit($event)"
				[showSpinner]="isPending"
			></ui-button>
		}
	}
</div>
