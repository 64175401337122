import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { SelectCustomOptionDirective } from '../../directives';

@Component({
	selector: 'ui-select-option',
	templateUrl: './select-option.component.html',
	styleUrls: ['./select-option.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SelectOptionComponent {
	@Input() option: SelectOption<any> | null = null;
	@Input() selected: boolean = false;
	@Input() multiEnabled: boolean = false;
	@Input() focused: boolean = false;
	@Input() customOptionTemplate: SelectCustomOptionDirective | undefined;
	@Input() disabled: boolean | undefined = false;
	@Input() fullWidth: boolean = false;

	@Output() optionSelected: EventEmitter<SelectOption<any>> = new EventEmitter<SelectOption<any>>();

	emitSelectedOption(): void {
		if (this.option && !this.option.isDisabled) {
			this.optionSelected.emit(this.option);
		}
	}
}
