import { environment } from '@academy/env/environment';
import { Headline } from '@agilox/ui-common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LessonContent } from '../../interfaces/lesson-content.interface';

@Component({
	selector: 'agilox-academy-lesson',
	templateUrl: './lesson.component.html',
	styleUrls: ['./lesson.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonComponent {
	@Input() lesson: LessonContent | undefined;
	@Input() headlineType: Headline = 'h3';
	@Input() enforcedLessonOrder: boolean = false;
	@Output() videoFinished: EventEmitter<number> = new EventEmitter<number>();

	baseUrl: string = environment.baseUrl;

	onVideoFinished(lessonId: number) {
		this.videoFinished.emit(lessonId);
	}
}
