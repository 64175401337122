<div class="ui-empty-state" [class.ui-empty-state--absolute]="isAbsolute">
	@if (image) {
		<img class="ui-empty-state__image" [src]="image" alt="empty-stage-image" />
	}
	<div
		class="ui-empty-state__message"
		[innerHTML]="text | translate"
		data-cy="empty-state-message"
	></div>
	@if (buttonText) {
		<ui-button
			[icon]="buttonIcon"
			(buttonClicked)="clicked($event)"
			[text]="buttonText | translate"
			data-cy="empty-state-button"
		>
		</ui-button>
	}
</div>
