<div class="ui-datepicker-base">
	<ui-datepicker-navigation
		[currentlyDisplayMonth]="currentlyDisplayedMonth"
		(dateChange)="navDateChange.emit($event)"
		(todayClick)="onTodayClick()"
		[minDate]="minDate"
		[maxDate]="maxDate"
		[disabled]="disabled"
	></ui-datepicker-navigation>
	<ui-datepicker-calendar
		[minDate]="minDate"
		[maxDate]="maxDate"
		[disabled]="disabled"
		[currentSelection]="selectedDate"
		[currentlyDisplayedMonth]="currentlyDisplayedMonth"
		[syncedDate]="syncedDate"
		(dateSelectionChanged)="dateSelectionChange.emit($event)"
		(monthChange)="navDateChange.emit($event)"
		(calendarWeekClicked)="calendarWeekClicked.emit($event)"
	></ui-datepicker-calendar>
</div>
