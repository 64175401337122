export enum PlanColors {
	PRIMARY = '#2E86D8',
	PRIMARY900 = '#256BAD',
	PRIMARY800 = '#2979C2',
	PRIMARY700 = '#2E86D8',
	PRIMARY600 = '#589EE0',
	PRIMARY500 = '#82B6E8',
	PRIMARY400 = '#A1C9ED',
	PRIMARY300 = '#C0DBF3',
	PRIMARY200 = '#D5E7F7',
	PRIMARY100 = '#EAF3FB',
}
