export enum DocsColors {
	PRIMARY = '#EE8800',
	PRIMARY900 = '#C46200',
	PRIMARY800 = '#DA6E00',
	PRIMARY700 = '#EE8800',
	PRIMARY600 = '#F1A033',
	PRIMARY500 = '#F4B259',
	PRIMARY400 = '#F7C98C',
	PRIMARY300 = '#FADBB2',
	PRIMARY200 = '#FCEDD9',
	PRIMARY100 = '#FDF3E5',
}
