import { FormControl } from '@angular/forms';

export function arrayLengthValidator(min: number, max?: number) {
	return (control: FormControl) => {
		if (control.value?.length < min || (max ? control.value?.length > max : false)) {
			return { arrayLength: true };
		}
		return null;
	};
}
