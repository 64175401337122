import { Pipe, PipeTransform } from '@angular/core';
import { dateStringToISO, userDateToISO } from '@agilox/common';

@Pipe({
	name: 'dateStringToUserDateString',
	standalone: true,
})
export class DateStringToUserDateStringPipe implements PipeTransform {
	transform(value: string | null): string | null {
		if (!value) {
			return null;
		}
		return userDateToISO(value);
	}
}
