<div class="ui-datepicker-calendar">
	<div class="ui-datepicker-calendar__calendarWeek">
		{{ 'datepicker.calendar_week' | translate }}
	</div>
	@for (weekDay of weekDays; track weekDay) {
		<div class="ui-datepicker-calendar__weekDay">
			{{ 'datepicker.weekday.' + weekDay | translate }}
		</div>
	}
	@for (week of calendarWeeks; track week.weekNumber) {
		<div class="ui-datepicker-calendar__calendarWeek" (click)="onCalendarWeekClicked(week)">
			{{ week.weekNumber }}
		</div>

		@for (day of week.days; track day.date) {
			<div
				class="ui-datepicker-calendar__day"
				[class.is-inactive]="disabled || day.disabled || day.notInActiveMonth"
				[class.is-disabled]="day.disabled || disabled"
				[class.is-selected]="isDateSelected(day)"
				[class.is-hovered]="isInRange(day)"
				(click)="selectDay(day)"
				(mouseenter)="hoverDay(day)"
				(mouseleave)="unhoverDay()"
				[uiTooltip]="(day.disabled ? 'datepicker.disabled' : '') | translate"
			>
				{{ day.date | date: 'dd' }}
			</div>
		}
	}
</div>
