<div
	class="flex justify-start rounded mb-3 p-2 pr-4 w-full border color-secondary gap-2"
	[ngClass]="{
		'bg-[#e5f2cc] border-success': type === 'success',
		'bg-[#fcedd9] border-warning ': type === 'warning',
		'bg-[#deedff] border-info': type === 'info',
	}"
>
	<ui-icon
		[icon]="notifyIcons[this.type]"
		[size]="sizes.M"
		[ngClass]="{
			'text-[#8ab82e]': type === 'success',
			'text-problem': type === 'warning',
			'text-info': type === 'info',
		}"
	></ui-icon>
	<div class="ui-notify__message ui-notify__message--{{ type }}" [innerHTML]="text"></div>
	@if (closable) {
		<div class="flex-grow flex justify-end pt-1">
			<ui-icon icon="x_cross" [action]="true" (iconClicked)="closeNotify.emit()"> </ui-icon>
		</div>
	}
</div>
