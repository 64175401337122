export enum ReportPerformanceColor {
	busy = '#99cc33',
	charge = '#B8DB70',
	idle = '#D6EBAD',
	wait = '#A6E4FF',
	protective = '#52CAFE',
	obstruction = '#00AAF3',
	t_issue = '#FFD800',
	p_issue = '#FFEC84',
	manual = '#CBCFD1',
	offline = '#8D959B',
}
