import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { NavColor, NavColors, MenuPoint } from '@agilox/ui-common';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuPointAction } from '@agilox/common';

@Component({
	selector: 'ui-nav-list',
	templateUrl: './nav-list.component.html',
	styleUrls: ['./nav-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListComponent {
	@Input() set items(items: Array<MenuPoint> | undefined) {
		this._items = items;
		this.topItems = items?.filter((item) => !item.position || item.position === 'top') || [];
		this.bottomItems = items?.filter((item) => item.position === 'bottom') || [];
	}
	private _items: Array<MenuPoint> | undefined;
	get items(): Array<MenuPoint> | undefined {
		return this._items;
	}

	@Input() openItem: MenuPoint | undefined;

	@Input() navColor: NavColor = NavColors.Dark;

	@Input() beta: boolean = false;

	topItems: Array<MenuPoint> | undefined;

	bottomItems: Array<MenuPoint> | undefined;

	@Output() menuPointAction: EventEmitter<MenuPointAction> = new EventEmitter<MenuPointAction>();

	@Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

	currentUrl: string = '';

	private router: Router = inject(Router);
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	constructor() {
		this.currentUrl = this.router.url;
		this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.currentUrl = event.urlAfterRedirects;
				this.cdRef.markForCheck();
			}
		});
	}

	handleEntryClick(menuPoint: MenuPoint) {
		this.menuPointAction.emit({ click: true, menuPoint: menuPoint });
	}
	handleMouseEnter(menuPoint: MenuPoint) {
		if (menuPoint.submenus) {
			this.menuPointAction.emit({ click: false, menuPoint: menuPoint });
		} else {
			this.closeMenu.emit();
		}
	}

	childMenuPointActive(menuPoint: MenuPoint) {
		let submenus: MenuPoint[] = menuPoint.submenus || [];
		return submenus.some(
			(submenu: MenuPoint) => submenu.active || (submenu.url === this.currentUrl && !!submenu.url)
		);
	}

	isMenuPointActive(menuPoint: MenuPoint): boolean {
		return (
			(menuPoint.url && this.currentUrl.includes(menuPoint.url)) ||
			menuPoint.active ||
			this.childMenuPointActive(menuPoint)
		);
	}
}
