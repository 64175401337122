import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { NavComponent } from './nav/nav.component';
import { HeadlineComponent } from '../headline/headline.component';
import { AppNavComponent } from './app-nav/app-nav.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { SubNavComponent } from './sub-nav/sub-nav.component';
import { NavListModule } from '../nav-list/nav-list.module';
import { IconModule } from '../icon/icon.module';
import { DirectivePageOverflowModule } from '../../../../directive/page-overflows/src';
import { MobileAppNavComponent } from './mobile-app-nav/mobile-app-nav.component';
import { TagComponent } from '../tag/tag.component';
import { DividerComponent } from '../divider/divider.component';
import { MobileSubNavListEntryComponent } from '../mobile-sub-nav-list-entry/mobile-sub-nav-list-entry.component';
import { AppBackgroundPipe } from './app-nav/app-background-pipe/app-background.pipe';
@NgModule({
	imports: [
		CommonModule,
		IconModule,
		MatIconModule,
		NavListModule,
		TranslateModule,
		RouterModule,
		TooltipModule,
		HeadlineComponent,
		DividerComponent,
		MobileSubNavListEntryComponent,
		DirectivePageOverflowModule,
		TagComponent,
		AppBackgroundPipe,
	],
	declarations: [
		MobileNavComponent,
		NavComponent,
		AppNavComponent,
		SubNavComponent,
		MobileAppNavComponent,
	],
	exports: [
		MobileNavComponent,
		NavComponent,
		AppNavComponent,
		SubNavComponent,
		MobileAppNavComponent,
	],
})
export class NavModule {}
