<p>{{ bodyText }}</p>
<div class="pt-6 flex flex-col gap-2">
	<ui-button
		[fullWidth]="true"
		[text]="submitButtonText"
		(buttonClicked)="onSubmit()"
		data-cy="confirmation-overlay-submit"
	></ui-button>
	<ui-button
		[fullWidth]="true"
		[text]="'general.cancel' | translate"
		type="link"
		(buttonClicked)="onCancel()"
		data-cy="confirmation-overlay-cancel"
	></ui-button>
</div>
