<container-element [ngSwitch]="type">
	<div *ngSwitchCase="'h1'" class="ui-headline">
		<h1 [class.is-light]="light" [class.is-ellipsed]="ellipsis">
			<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
		</h1>
	</div>

	<div *ngSwitchCase="'h2'" class="ui-headline">
		<h2 [class.is-light]="light" [class.is-ellipsed]="ellipsis">
			<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
		</h2>
	</div>

	<h3
		[class.is-light]="light"
		[class.is-ellipsed]="ellipsis"
		*ngSwitchCase="'h3'"
		class="ui-headline"
	>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</h3>
	<h4
		[class.is-light]="light"
		[class.is-ellipsed]="ellipsis"
		*ngSwitchCase="'h4'"
		class="ui-headline"
	>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</h4>
</container-element>

<ng-template #contentTpl>
	<ng-content></ng-content>
</ng-template>
