export enum SecondaryColors {
	secondary = '#393e42',
	secondary900 = '#2E3235',
	secondary800 = '#393e42',
	secondary700 = '#4F5B64',
	secondary600 = '#6E7880',
	secondary500 = '#8D959B',
	secondary400 = '#ACB2B6',
	secondary300 = '#CBCFD1',
	secondary200 = '#EAECED',
	secondary100 = '#F5F5F6',
}
