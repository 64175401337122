import { Company } from './company.interface';
import { Address } from '@agilox/common';

export interface Partner {
	uuid: string;
	name: string;
	id: number;
	address?: Address;
	customers?: Company[];
	deleted?: boolean;
}
