<div class="ui-textarea">
	<textarea
		[id]="'textarea-' + inputId"
		[formControl]="formControl"
		class="ui-textarea__textarea"
		[placeholder]="placeholder"
		(focusout)="onFocusOut()"
		(focusin)="focused = true"
		[ngClass]="{
			'ui-textarea__textarea--valid':
				!focused && formControl.valid && showWhenValid && formControl.value,
			'ui-textarea--error': !focused && formControl.invalid && formControl.value,
			'ui-textarea--resize': !resizeDisabled,
		}"
	></textarea>
</div>
