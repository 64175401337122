import { MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import { Component, Input } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../tooltip/tooltip.module';

@Component({
	selector: 'ui-nav-list-entry',
	standalone: true,
	imports: [IconModule, TranslateModule, TooltipModule],
	templateUrl: './nav-list-entry.component.html',
	styleUrls: ['./nav-list-entry.component.scss'],
})
export class NavListEntryComponent {
	@Input() menuPoint: MenuPoint | undefined;
	@Input() navColor: NavColor = NavColors.Dark;
	@Input() isActive: boolean = false;
	@Input() isHovered: boolean = false;
}
