import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MenuPointUrl } from '../../enums/menupoint-url.enum';
import { AuthenticationService } from '../../services/authentication.service';

export const authGuard: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
	return inject(AuthenticationService).isLoggedIn;
};
