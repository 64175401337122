import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT, GOOGLE_TAG_MANAGER_ID } from '@agilox/common';
import { Environment } from '../interfaces/environment.interface';
import { ROLE } from '../tokens/role.token';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GoogleAnalyticsService {
	constructor(
		private router: Router,
		@Inject(ENVIRONMENT) private environment: Environment,
		@Inject(ROLE) private role: Observable<string>,
		@Inject(GOOGLE_TAG_MANAGER_ID) private gtmId: string,
		@Inject(DOCUMENT) private document: Document
	) {
		this.initializeScript();
	}

	public initializeScript() {
		const gtmScriptId = 'gtm-script';
		if (document.getElementById(gtmScriptId)) {
			return;
		}

		const gtmScript = document.createElement('script');
		gtmScript.id = gtmScriptId;
		gtmScript.async = true;

		const dl = 'dataLayer' !== 'dataLayer' ? '&l=dataLayer' : ''; // Correctly construct the `dl` parameter
		gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}${dl}`;
		document.head.appendChild(gtmScript);

		(window as any).dataLayer = (window as any).dataLayer || [];
		(window as any).dataLayer.push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});
		const noscript = document.createElement('noscript');
		noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
		document.body.insertBefore(noscript, document.body.firstChild);
	}

	public eventEmitter(eventAction: string, label: any = null) {
		if (document.cookie.includes('statisticCookies=1') && this.environment.production) {
			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				event: 'click event',
				event_name: eventAction + (label ? ': ' + label : ''),
				event_category: 'page: ' + this.router.url,
				dimension: this.role,
			});
		}
	}
}
