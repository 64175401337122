import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MobileNavListComponent } from './mobile-nav-list/mobile-nav-list.component';
import { NavListComponent } from './nav-list/nav-list.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { IconModule } from '../icon/icon.module';
import { MobileSubNavListEntryComponent } from '../mobile-sub-nav-list-entry/mobile-sub-nav-list-entry.component';
import { NavListEntryComponent } from '../nav-list-entry/nav-list-entry.component';
import { TagComponent } from '../tag/tag.component';

@NgModule({
	imports: [
		CommonModule,
		TooltipModule,
		IconModule,
		RouterModule,
		TranslateModule,
		TagComponent,
		MobileSubNavListEntryComponent,
		NavListEntryComponent,
	],
	declarations: [MobileNavListComponent, NavListComponent],
	exports: [MobileNavListComponent, NavListComponent],
})
export class NavListModule {}
