import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'ui-select-search',
	templateUrl: './select-search.component.html',
	styleUrls: ['./select-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements AfterViewChecked {
	@Input() placeholder: string = '';

	@Output() searched: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement> | undefined;

	public formControl: FormControl = new FormControl();

	constructor() {
		this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((val) => {
			this.searched.emit(val);
		});
	}

	clearSearch(): void {
		this.formControl.setValue('');
	}

	ngAfterViewChecked(): void {
		if (this.searchInput) {
			this.searchInput.nativeElement.focus();
		}
	}
}
