import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
	selector: 'ui-print-button',
	standalone: true,
	imports: [ButtonComponent],
	templateUrl: './print-button.component.html',
	styleUrls: ['./print-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintButtonComponent {
	print() {
		window.print();
	}
}
