import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { IconModule } from '../icon/icon.module';

@Component({
	selector: 'ui-removable-filter-button',
	templateUrl: './removable-filter-button.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IconModule],
})
export class RemovableFilterButtonComponent {
	@Output() removeClick: EventEmitter<void> = new EventEmitter<void>();
}
