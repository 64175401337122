<div class="ui-button-toggle-group">
	@for (button of buttons; track $index) {
		<button
			[attr.data-cy]="'ui-button-toggle-' + $index"
			class="ui-button-toggle ui-button-toggle--{{ size }}"
			(click)="onClick(button)"
			[ngClass]="{
				'ui-button-toggle--selected': isValueSelected(button.value),
				'ui-button-toggle--disabled': button.disabled,
				'ui-button-toggle--fullWidth': fullWidth,
			}"
		>
			<ng-container
				*ngTemplateOutlet="
					buttonToggleContentDirective?.templateRef ?? null;
					context: { $implicit: button }
				"
			></ng-container>
			<ng-content></ng-content>
		</button>
	}
</div>
