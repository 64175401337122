import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Position, Positions } from '@agilox/ui-common';

/**
 * This component is positioned by its respective directive. It should never be used alone and therefore it is not exposed by the public api
 */
@Component({
	selector: 'ui-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
	position: Position = Positions.Top;
	@Input() tooltip: string = '';
	@Input() left: number = 0;
	@Input() top: number = 0;
	@Input() visible: boolean = false;
	@Input() styleClass: string = '';
}
