import { OnboardingAction, OnboardingInterface } from '@agilox/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayComponent } from '../overlay/overlay.component';
import { OnboardingSlideComponent } from './components/onboarding-slide/onboarding-slide.component';

@Component({
	selector: 'ui-onboarding',
	templateUrl: './onboarding.component.html',
	standalone: true,
	imports: [OverlayComponent, OnboardingSlideComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent {
	protected readonly OnboardingAction = OnboardingAction;
	index: number = 0;

	@Input() slides!: OnboardingInterface[];

	@Output() onboardingWatched: EventEmitter<void> = new EventEmitter<void>();

	onButtonClicked(action: OnboardingAction): void {
		switch (action) {
			case OnboardingAction.DONE:
			case OnboardingAction.SKIP:
				this.onboardingWatched.emit();
				break;
			case OnboardingAction.NEXT:
				this.setNextSlide();
				break;
			case OnboardingAction.BACK:
				this.setPreviousSlide();
				break;
		}
	}

	setNextSlide(): void {
		if (this.index < this.slides.length - 1) {
			this.index++;
		}
	}

	setPreviousSlide(): void {
		if (this.index > 0) {
			this.index--;
		}
	}
}
