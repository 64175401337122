import { Sizes, TagType, Tags } from '@agilox/ui-common';
import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconModule } from '../icon/icon.module';

@Component({
	selector: 'ui-tag',
	standalone: true,
	imports: [IconModule, NgStyle],
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
	@Input() type: TagType = Tags.PrimaryLight;
	@Input() text: string = '';
	@Input() icon: string | undefined;
	@Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();
	public sizes = Sizes;

	onIconClick() {
		this.iconClicked.emit();
	}

	protected readonly Sizes = Sizes;
}
