import {
	App,
	PlanColors,
	DocsColors,
	AcademyColors,
	AnalyticsColors,
	SelftestColors,
	LifecycleColors,
	MyColors,
} from '@agilox/common';
import { AppColorDefinition } from '@agilox/ui-common';

export const AppColors: { [key: string]: AppColorDefinition } = {
	[App.my]: {
		color: MyColors.PRIMARY,
		background: MyColors.PRIMARY100,
		backgroundHover: MyColors.PRIMARY100,
		buttonHover: MyColors.PRIMARY800,
	},
	[App.analytics]: {
		color: AnalyticsColors.PRIMARY,
		background: AnalyticsColors.PRIMARY100,
		backgroundHover: AnalyticsColors.PRIMARY100,
		buttonHover: AnalyticsColors.PRIMARY800,
	},
	[App.academy]: {
		color: AcademyColors.PRIMARY,
		background: AcademyColors.PRIMARY100,
		backgroundHover: AcademyColors.PRIMARY100,
		buttonHover: AcademyColors.PRIMARY800,
	},
	[App.docs]: {
		color: DocsColors.PRIMARY,
		background: DocsColors.PRIMARY100,
		backgroundHover: DocsColors.PRIMARY100,
		buttonHover: DocsColors.PRIMARY800,
	},
	[App.selftest]: {
		color: SelftestColors.PRIMARY,
		background: SelftestColors.PRIMARY100,
		backgroundHover: SelftestColors.PRIMARY100,
		buttonHover: SelftestColors.PRIMARY800,
	},
	[App.lifecycle]: {
		color: LifecycleColors.PRIMARY,
		background: LifecycleColors.PRIMARY100,
		backgroundHover: LifecycleColors.PRIMARY100,
		buttonHover: LifecycleColors.PRIMARY800,
	},
	[App.plan]: {
		color: PlanColors.PRIMARY,
		background: PlanColors.PRIMARY100,
		backgroundHover: PlanColors.PRIMARY100,
		buttonHover: PlanColors.PRIMARY800,
	},
} as const;
