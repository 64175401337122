import { App, ErrorService, MyRoutes } from '@agilox/common';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { UserSettingsService } from '../services/user-settings.service';
import { ErrorType } from '@agilox/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private errorService: ErrorService,
		private userSettingsService: UserSettingsService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isMenuUrl = this.authenticationService.isMenuUrl(this.router.routerState.snapshot.url);
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.handleUnauthorizedError();
				}
				if (
					!error.ok &&
					(!this.authenticationService.isLoggedIn || !localStorage.getItem('profile'))
				) {
					this.routeToLogin();
				} else {
					return this.handleNetworkError(req, error);
				}
				return of(new HttpResponse({ status: error.status, body: {} }));
			}),
			tap((evt: HttpEvent<any>) => {
				if (evt instanceof HttpResponse) {
					if (
						!this.authenticationService.isLoggedIn &&
						!localStorage.getItem('profile') &&
						isMenuUrl
					) {
						this.routeToLogin();
					}
					if (evt.status === 200 && evt.body && evt.body.error === 'user_not_loggedin') {
						this.handleUnauthorizedError();
					}
				}
			}),
			catchError((error) => {
				if (!navigator.onLine) {
					this.errorService.setError({
						application: App.academy,
						url: req.url,
						user: this.userSettingsService.user,
						type: ErrorType.NO_INTERNET_ERROR,
					});
				}
				return throwError(() => error);
			})
		);
	}

	private handleUnauthorizedError(): void {
		this.authenticationService.isLoggedIn = false;
		this.userSettingsService.clearLocalStorage();
		this.routeToLogin();
	}

	private routeToLogin(): void {
		window.location.href =
			MyRoutes[this.authenticationService.environment as keyof typeof MyRoutes] +
			'/#/login?app=academy&error=session';
	}

	private handleNetworkError(req: HttpRequest<any>, error: any): Observable<any> {
		this.errorService.setError({
			application: App.academy,
			user: this.userSettingsService.user,
			message: error.message,
			url: req.url,
			type: ErrorType.NETWORK_ERROR,
		});
		return of(new HttpResponse({ body: {} }));
	}
}
