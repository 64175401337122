import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';

@Pipe({
	name: 'selectInputText',
	pure: false,
})
export class SelectInputTextPipe implements PipeTransform {
	/**
	 * Transforms the selected options into a string to be displayed in the input field
	 * e.g. "Option 1, +3 more"
	 * @param value
	 */
	transform(value: Array<SelectOption<any>> | SelectOption<any>): string {
		let result: string = '';
		if (value && Array.isArray(value) && value.length) {
			result = value[0].title;
		} else if (value && !Array.isArray(value)) {
			result = value.title;
		}
		return result;
	}
}
