import { NgModule } from '@angular/core';
import { DatepickerComponent } from './datepicker.component';
import { CommonModule, DatePipe } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { InputModule } from '../input/input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from '../select/select.module';
import { DatepickerBaseModule } from './datepicker-base/datepicker-base.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DatepickerInputComponent } from './datepicker-input/datepicker-input.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { DateStringToUserDateStringPipe } from '@agilox/common';

@NgModule({
	declarations: [DatepickerComponent, DatepickerInputComponent],
	imports: [
		CommonModule,
		IconModule,
		InputModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		SelectModule,
		DatepickerBaseModule,
		TooltipModule,
		DropdownModule,
		DateStringToUserDateStringPipe,
	],
	exports: [DatepickerComponent],
	providers: [DatePipe],
})
export class DatepickerModule {}
