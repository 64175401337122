import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * Custom URL encoder that prevents certain encodings.
 */
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
	private static readonly leftBracketPattern = new RegExp('%5B', 'g');
	private static readonly rightBracketPattern = new RegExp('%5D', 'g');

	override encodeKey(key: string): string {
		return super
			.encodeKey(key)
			.replace(CustomHttpUrlEncodingCodec.leftBracketPattern, '[')
			.replace(CustomHttpUrlEncodingCodec.rightBracketPattern, ']');
	}
}
