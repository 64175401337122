import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { VehicleSelectUnionCheckboxPipe } from './pipes/vehicle-select-union-checkbox.pipe';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { PipeEmptyModule } from '@agilox/pipe/empty';

@Component({
	selector: 'ui-union-select-option',
	templateUrl: './union-select-option.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CheckboxComponent,
		VehicleSelectUnionCheckboxPipe,
		NgTemplateOutlet,
		IconModule,
		NgClass,
		PipeEmptyModule,
	],
})
export class UnionSelectOptionComponent {
	@Input() union: string = '';

	private _options: SelectOption<Vehicle>[] | null = null;

	@Input() set options(options: SelectOption<Vehicle>[] | null) {
		this._options = options;
	}

	@Input() selectedVehicles: Vehicle[] = [];

	@Input() toggleable: boolean = false;

	@Input() collapsed: boolean = false;

	@Output() deselectAll: EventEmitter<void> = new EventEmitter<void>();

	/** Select all vehicles in this union */
	@Output() selectAll: EventEmitter<void> = new EventEmitter<void>();

	/** In use when toggleable is true */
	@Output() toggled: EventEmitter<void> = new EventEmitter<void>();

	get options(): SelectOption<Vehicle>[] | null {
		return this._options;
	}

	public handleClick() {
		if (this.allPossibleOptionsSelected()) {
			this.deselectAll.emit();
		} else {
			this.selectAll.emit();
		}
	}

	private allPossibleOptionsSelected() {
		let unionVehicles =
			this.options?.filter(
				(option: SelectOption<Vehicle>) => option.value?.union === this.union && option.value
			) || [];
		return unionVehicles.every((unionVehicle: SelectOption<Vehicle>) =>
			this.selectedVehicles.find(
				(vehicle: Vehicle) => vehicle.serial === unionVehicle.value!.serial
			)
		);
	}

	/**
	 * Ignore the checkbox functionality,
	 * let the component handle the click event
	 * @param event
	 */
	public handleCheckboxClick(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.handleClick();
	}

	public onToggle() {
		this.toggled.emit();
	}
}
