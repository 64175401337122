<div
	class="ui-select-input"
	[ngClass]="{
		'ui-select-input--disabled': disabled,
		'ui-select-input--error': hasError,
		'ui-select-input--valid': !hasError && showWhenValid,
	}"
>
	<div class="ui-select-input__inputWrapper">
		<div class="ui-select-input__input" [ngClass]="{ 'is-focused': opened }" [id]="inputId">
			<div class="ui-select-input__inputField" [attr.data-cy]="'ui-select-input-input-field'">
				<ng-container *ngIf="!value; else valueTemplate">
					<span class="ui-select-input__placeholder">{{ placeholder }}</span>
				</ng-container>
				<ng-template #valueTemplate>
					<span class="ui-select-input__value" data-cy="ui-select-input-field-value">
						{{ value }}
					</span>
					@if (amountSelected > 1) {
						<ui-pill
							[value]="'+' + (amountSelected - 1).toString()"
							class="ui-select-input__pill"
							type="secondaryOutline"
							[disabled]="disabled"
						></ui-pill>
					}
				</ng-template>
			</div>

			<div class="ui-select-input__icons">
				@if (showClearButton) {
					<ui-icon
						icon="x_cross"
						class="ui-select-input__icon"
						size="s"
						(iconClicked)="onClearSelection($event)"
					></ui-icon>
				} @else {
					<ui-icon
						icon="x_arrow_down"
						class="ui-select-input__icon"
						[class.is-open]="opened"
						size="m"
					></ui-icon>
				}
			</div>
		</div>
	</div>
</div>
