<div class="ui-nav-list">
	<div class="ui-nav-list--top">
		<ui-nav-list-entry
			*ngFor="let menuPoint of topItems"
			[menuPoint]="menuPoint"
			[navColor]="navColor"
			[isActive]="isMenuPointActive(menuPoint)"
			[isHovered]="menuPoint === openItem"
			(click)="handleEntryClick(menuPoint)"
			(mouseenter)="handleMouseEnter(menuPoint)"
			[attr.data-id]="menuPoint.url"
			[attr.data-cy]="'nav-item-' + menuPoint.name"
			class="ui-nav-list__entry"
		>
		</ui-nav-list-entry>
	</div>
	<div class="ui-nav-list--bottom">
		@if (beta) {
			<div class="ui-nav-list__betaTag">
				<ui-tag [text]="'general.beta' | translate" type="dark"></ui-tag>
			</div>
		}

		<ui-nav-list-entry
			*ngFor="let menuPoint of bottomItems"
			[menuPoint]="menuPoint"
			[navColor]="navColor"
			[isActive]="isMenuPointActive(menuPoint)"
			[isHovered]="menuPoint === openItem"
			(click)="handleEntryClick(menuPoint)"
			(mouseenter)="handleMouseEnter(menuPoint)"
			[attr.data-id]="menuPoint.url"
			[attr.data-cy]="'nav-item-' + menuPoint.name"
			class="ui-nav-list__entry"
		>
		</ui-nav-list-entry>
	</div>
</div>
