@if (menuPoint) {
	<div
		class="ui-nav-list-entry"
		[class.is-active]="isActive"
		[class.is-hovered]="isHovered"
		[attr.data-id]="menuPoint.url"
		[uiTooltip]="menuPoint.description ?? ''"
		[tooltipPosition]="menuPoint.tooltipPosition ?? 'right'"
	>
		<ui-icon class="ui-nav-list-entry__icon" size="l" [type]="navColor" [icon]="menuPoint.icon">
		</ui-icon>
		<span class="ui-nav-list-entry__text ui-nav-list-entry__text--{{ navColor }}">
			{{ menuPoint.name | translate }}
		</span>
	</div>
}
