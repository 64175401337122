import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Pills, PillType } from '@agilox/ui-common';
import { NgClass } from '@angular/common';

@Component({
	selector: 'ui-pill',
	standalone: true,
	templateUrl: './pill.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class PillComponent {
	@Input() value: string | undefined;
	@Input() type: PillType = Pills.SecondaryFilled;
	@Input() disabled: boolean = false;

	get classes(): string {
		const baseClasses: { [key: string]: string } = {
			secondaryFilled: 'bg-secondary-800 border-secondary-800 text-white',
			secondaryOutline: 'bg-transparent border-secondary-800 text-secondary-800',
		};

		const disabledClasses: { [key: string]: string } = {
			secondaryFilled: 'bg-secondary-100 border-secondary-100 text-secondary-400',
			secondaryOutline: 'bg-transparent border-secondary-400 text-secondary-400',
		};

		if (this.disabled) {
			return disabledClasses[this.type] || '';
		}
		return baseClasses[this.type] || '';
	}
}
