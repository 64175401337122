export const Icons = {
	Light: 'light',
	Dark: 'dark',
	Primary: 'primary',
	Warning: 'warning',
	Error: 'error',
	Problem: 'problem',
	Success: 'success',
	Input: 'input',
	Active: 'active',
	Action: 'action',
} as const;
