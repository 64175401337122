import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { GoogleAnalyticsService } from '@agilox/common';

@Directive({
	selector: '[directiveGoogleAnalytics]',
	standalone: true,
})
export class GoogleAnalyticsDirective {
	@Input() eventAction: string = '';
	@Input() eventLabel: any = null;

	constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

	@HostListener('click')
	onClick() {
		this.googleAnalyticsService.eventEmitter(this.eventAction, this.eventLabel);
	}
}
