import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
const excludedUrls = ['connect'];

/**
 * enables our login-system by setting the credentials and return type of requests
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
	/**
	 * adds the content-type and withCredentials
	 * @param request
	 * @param next
	 */
	// prettier-ignore
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(
			excludedUrls.some((url) => request.url.includes(url))
				? request
				: request.clone({
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
					}),
					withCredentials: true,
				})
		);
	}
}
