@let count = (notificationsCount$ | async) ?? 0;

<ui-button
	(buttonClicked)="toggleNotifications()"
	type="blue"
	[inverted]="count === 0"
	[uiBadge]="count > 0 ? count.toString() : ''"
	[icon]="count > 0 ? 'bell_ring' : 'bell'"
	data-cy="notification-button"
></ui-button>
