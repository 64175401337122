<div class="ui-mobile-nav" (click)="clickInsideNav($event)">
	@if (appNavVisible) {
		<div class="ui-mobile-nav__entry">
			<ui-mobile-app-nav
				[apps]="apps"
				[activeApp]="activeApp"
				[environment]="environment"
				[navColor]="navColor"
				[open]="appNavOpen"
				(toggled)="onAppNavToggled($event)"
				class="ui-mobile-nav__appNav"
			></ui-mobile-app-nav>
		</div>
	}
	<div
		class="ui-mobile-nav__entry"
		*ngFor="let item of items"
		(click)="onMenuPointClick(item)"
		[class.is-active]="
			item === openedMenuPoint || (item.url.includes(currentUrl) && item.url !== '')
		"
	>
		<ui-icon [type]="navColor" [icon]="item.icon" size="m"> </ui-icon>
		<div
			class="ui-mobile-nav__text ui-mobile-nav--{{ navColor }}"
			[ngClass]="'ui-mobile-nav__text--' + navColor"
		>
			{{ item.name | translate }}
		</div>
		<ui-mobile-nav-list
			*ngIf="item.submenus"
			[submenuItems]="item.submenus"
			[isOpen]="item === openedMenuPoint"
			[currentUrl]="currentUrl"
			(closeMenu)="closeMenu()"
			(click)="clickInsideNav($event)"
			(routeMenuPointClicked)="doRouting($event)"
			[navColor]="navColor"
			(callbackEvent)="callbackEvent.emit($event)"
		></ui-mobile-nav-list>
	</div>
</div>
