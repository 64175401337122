import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class Unsubscribe implements OnDestroy {
	destroy$: Subject<void> = new Subject<void>();

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
