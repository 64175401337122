import {
	ChangeDetectionStrategy,
	Component,
	effect,
	ElementRef,
	EventEmitter,
	HostListener,
	input,
	Input,
	InputSignal,
	OnInit,
	Output,
} from '@angular/core';

@Component({
	selector: 'ui-video',
	templateUrl: './video.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit {
	id: InputSignal<string | undefined> = input<string | undefined>();
	@Input() playbarVisible: boolean = true;

	@Output() videoFinished: EventEmitter<void> = new EventEmitter<void>();

	private wistiaVideo: any;

	constructor(private elementRef: ElementRef) {
		effect(() => {
			if (this.id()) {
				this.applyConfig();
				this.addVideo();
			}
		});
	}

	ngOnInit(): void {
		this.addScript();
	}

	wistiaPlayerConfig = {
		playerColor: '526d7e',
		settingsControl: false,
		playSuspendedOffScreen: true,
		branding: false,
		videoFoam: true,
		copyLinkAndThumbnailEnabled: false,
		googleAnalytics: true,
		resumable: true,
	};

	private addScript(): void {
		const script: HTMLScriptElement = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
		script.async = true;
		this.elementRef.nativeElement.appendChild(script);
	}

	private applyConfig() {
		(window as any)._wq = (window as any)._wq || [];
		(window as any)._wq.push({
			id: this.id(),
			options: {
				...this.wistiaPlayerConfig,
				playbar: this.playbarVisible,
			},
			onReady: (video: any) => {
				this.wistiaVideo = video;

				this.addRewindButton();

				this.wistiaVideo.bind('end', () => {
					this.videoFinished.emit();
				});
			},
		});
	}

	private addVideo() {
		this.removeVideo();
		const video = document.createElement('div');
		video.className = 'wistia_embed wistia_async_' + this.id();
		const container = this.elementRef.nativeElement.querySelector('.c-video__container');
		container?.appendChild(video);
	}

	private addRewindButton() {
		const button = document.createElement('div');
		button.className = 'c-video__control';
		const container = document.querySelector('.w-bottom-bar-middle-inner');
		container?.appendChild(button);

		button.addEventListener('click', () => {
			if (this.wistiaVideo?.time() > 0) {
				this.wistiaVideo?.time(this.wistiaVideo?.time() - 10);
			}
		});
	}

	private removeVideo() {
		const video = this.elementRef.nativeElement.querySelector('.wistia_embed');
		video?.remove();
	}

	@HostListener('document:visibilitychange')
	private onVisibilityChange(): void {
		if (document.hidden) {
			this.wistiaVideo?.pause();
		}
	}

	/**
	 * Shortcut to set the video to watched
	 * used for debugging purposes
	 * SHIFT + W
	 * @private
	 */
	@HostListener('document:keydown', ['$event'])
	private setVideoToWatched(event: any): void {
		if (event.shiftKey && event.key.toLowerCase() === 'w') {
			this.videoFinished.emit();
		}
	}
}
