import { MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
@Component({
	selector: 'ui-mobile-nav-list',
	templateUrl: './mobile-nav-list.component.html',
	styleUrls: ['./mobile-nav-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavListComponent {
	/** if submenu is open */
	@Input() isOpen: boolean | undefined;

	/** items */
	@Input() submenuItems: MenuPoint[] | undefined;

	@Input() navColor: NavColor = NavColors.Dark;

	@Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

	currentlyOpenedSubmenu: MenuPoint | undefined;

	@Input() currentUrl: string = '';

	@Output() callbackEvent: EventEmitter<MenuPoint> = new EventEmitter();

	@Output() routeMenuPointClicked: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public router: Router
	) {}

	toggleEntry(item: MenuPoint) {
		if (item.callback) {
			this.callbackEvent.emit(item);
		} else if (!item.submenus?.length || !item.submenus.length) {
			this.routeMenuPointClicked.emit(item);
		} else {
			if (this.currentlyOpenedSubmenu === item) {
				this.currentlyOpenedSubmenu = undefined;
			} else {
				this.currentlyOpenedSubmenu = item;
				this.scrollIntoView(item);
			}
			this.changeDetectorRef.markForCheck();
		}
	}

	scrollIntoView(item: MenuPoint) {
		const element = document.querySelector(`[data-id="sub-nav-entry-${item.name}"]`);
		if (element) {
			/** Timeout needed for scrollIntoView to work because of the animation */
			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}, 100);
		}
	}

	childrenAreActive(item: MenuPoint): boolean {
		if (item.submenus) {
			return item.submenus.some((submenu: MenuPoint) => {
				let active: boolean = false;
				if (submenu.url) {
					active = this.currentUrl.includes(submenu.url);
				}
				if (submenu.active !== undefined) {
					active = submenu.active;
				}
				return active;
			});
		}
		return false;
	}
}
