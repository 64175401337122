<div class="ui-rich-text-editor-toolbar" [id]="id">
	<button (click)="onUndo()">
		<ui-icon icon="x_arrow_left"></ui-icon>
	</button>
	<button (click)="onRedo()">
		<ui-icon icon="x_arrow_right"></ui-icon>
	</button>
	@for (item of toolbarItems; track item) {
		@if (item.value) {
			<button class="ql-{{ item.class }}" [value]="item.value"></button>
		} @else {
			<button class="ql-{{ item.class }}"></button>
		}
	}
</div>
