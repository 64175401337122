import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';

@Pipe({
	name: 'monthSelect',
})
export class MonthSelectPipe implements PipeTransform {
	transform(value: SelectOption<number>[], ...args: unknown[]): SelectOption<number>[] {
		const minDate: Date = args[1] as Date;
		const maxDate: Date = args[2] as Date;
		const currentYear: number = args[0] as number;

		return this.disableOptions(
			value.map((option) => {
				return {
					...option,
					isDisabled: false,
				};
			}),
			minDate,
			maxDate,
			currentYear
		);
	}

	/**
	 * Disable options based on minDate and maxDate
	 * A month is disabled if:
	 * - the year is the same as the minDate year and the month is smaller than the minDate month
	 * - the year is smaller than the minDate year
	 * - the year is the same as the maxDate year and the month is greater than the maxDate month
	 * - the year is greater than the maxDate year
	 * @param value
	 * @param minDate
	 * @param maxDate
	 * @param currentYear
	 * @private
	 */

	private disableOptions(
		value: SelectOption<number>[],
		minDate: Date,
		maxDate: Date,
		currentYear: number
	): SelectOption<number>[] {
		return value.map((option) => {
			if (minDate) {
				if (
					currentYear < minDate.getFullYear() ||
					(currentYear === minDate.getFullYear() && option.value! < minDate.getMonth() + 1)
				) {
					option.isDisabled = true;
				}
			}

			if (maxDate) {
				if (
					currentYear > maxDate.getFullYear() ||
					(currentYear === maxDate.getFullYear() && option.value! > maxDate.getMonth() + 1)
				) {
					option.isDisabled = true;
				}
			}
			return option;
		});
	}
}
