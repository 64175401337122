import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * transforms the string to trusted html
 */
@Pipe({
	name: 'trusted',
})
export class TrustedPipe implements PipeTransform {
	/**
	 * used for inject the sanitizer
	 * @param sanitizer sanitizer for the html
	 */
	constructor(private sanitizer: DomSanitizer) {}

	/**
	 * transforms the string to trusted html
	 * @param value the html
	 * @param args nothing should be given
	 */
	transform(value: any, ...args: any[]): any {
		if (value === undefined) {
			return '';
		}

		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
}
