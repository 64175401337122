/**
 * Both stage and test are required
 * as academy is deployed on test and as such the stage environment is not available
 * but it is needed for other apps to access the academy
 */
export const AcademyRoutes = {
	Local: 'http://localhost:1300',
	LocalLive: 'http://localhost:1310',
	Cypress: 'http://localhost:1320',
	Test: 'https://academy.test.agilox.net',
	Stage: 'https://academy.test.agilox.net',
	Beta: 'https://academy.beta.agilox.net',
	Live: 'https://academy.agilox.net',
} as const;
