import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

export const logoutGuard: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
	const authenticationService = inject(AuthenticationService);
	authenticationService.logout();
	return of(true);
};
