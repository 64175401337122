<div
	data-cy="c-removable-filter-button"
	class="flex items-center justify-between border-secondary-400 rounded-xl border max-h-6 justify-center pointer gap-0.5 m-0.5 ml-0 px-2 py-1 hover:border-secondary-800 transition"
>
	<div
		class="text-secondary-800 select-none text-xs text-ellipsis overflow-hidden whitespace-nowrap"
	>
		<ng-content></ng-content>
	</div>
	<ui-icon
		icon="x_cross"
		size="s"
		class="text-secondary-500 cursor-pointer hover:text-secondary-800 transition"
		data-cy="ui-removable-filter-button-deselect"
		(click)="removeClick.emit()"
	></ui-icon>
</div>
