import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../tooltip/tooltip.module';
import { IconModule } from '../icon/icon.module';
import { InputComponent } from './input.component';
import { ButtonComponent } from '../button/button.component';

@NgModule({
	declarations: [InputComponent],
	exports: [InputComponent],
	imports: [
		CommonModule,
		IconModule,
		FormsModule,
		ReactiveFormsModule,
		TooltipModule,
		ButtonComponent,
	],
})
export class InputModule {}
