import { Role } from '@agilox/common';
import { Company } from './company.interface';

export interface User {
	uuid: string;
	company_name: string;
	company: Company;
	email: string;
	firstname: string;
	lastname: string;
	phone_number: string;
	role: Role;
	last_login: string;
	vehicles?: string;
	lang: string;
	active: boolean;
	maas_billing: boolean;
}
