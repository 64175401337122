<div
	class="c-media-list"
	[ngClass]="'c-media-list--' + viewMode"
	[attr.data-cy]="'c-media-list-' + viewMode"
>
	<div
		class="c-media-list__element"
		[ngClass]="'c-media-list__element--' + viewMode"
		*ngFor="let mediaElement of media"
	>
		<ui-icon
			[size]="viewMode === 'card' ? '3xl' : '2xl'"
			[icon]="mediaElement.extension === 'pdf' ? 'pdf' : 'media'"
			type="primary"
			class="c-media-list__icon"
		></ui-icon>

		<div class="c-media-list__main">
			<div class="c-media-list__details">
				{{ mediaElement.title ? mediaElement.title : mediaElement.name }}.{{
					mediaElement.extension
				}}
			</div>
			<div class="c-media-list__actions">
				<ui-button
					class="c-media-list__action"
					icon="visibility_on"
					type="secondary"
					(click)="preview(mediaElement)"
				>
				</ui-button>
				<a
					[href]="environment.fileserver + '/' + mediaElement.pathOriginal"
					[download]="mediaElement.name"
					directiveGoogleAnalytics
					eventAction="'click: download-media'"
					class="c-media-list__action"
				>
					<ui-button
						icon="download"
						[hideTextOnMobile]="true"
						[text]="'media.download' | translate"
						type="secondary"
					>
					</ui-button>
				</a>
			</div>
		</div>
	</div>
</div>
