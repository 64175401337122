export enum MyColors {
	PRIMARY = '#9C3',
	PRIMARY900 = '#82ad2b',
	PRIMARY800 = '#8ebd2f',
	PRIMARY700 = '#9c3',
	PRIMARY600 = '#a8d452',
	PRIMARY500 = '#b8db70',
	PRIMARY400 = '#c7e38f',
	PRIMARY300 = '#d6ebad',
	PRIMARY200 = '#e5f2cc',
	PRIMARY100 = '#f5faeb',
}
