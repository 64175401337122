import { App, Role } from '@agilox/common';

export const AppRoles = {
	[App.my]: [],
	[App.analytics]: [],
	[App.academy]: [],
	[App.docs]: [],
	[App.service]: [Role.support, Role.superuser],
	[App.selftest]: [Role.support, Role.superuser],
	[App.lifecycle]: [Role.support, Role.superuser, Role.partner, Role.service],
	[App.plan]: [Role.superuser],
} as const;
