import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { SvgIconType } from '@ngneat/svg-icon/lib/providers';
import allIcons from './library/svg-icons';
import { IconComponent } from './icon.component';

const iconMap: any = { ...allIcons };
export const ICONS: SvgIconType[] = Object.keys(iconMap).map((name) => {
	return {
		name,
		data: iconMap[name],
	};
});

@NgModule({
	declarations: [IconComponent],
	exports: [SvgIconComponent, IconComponent],
	imports: [SvgIconComponent, CommonModule],
	providers: [provideSvgIcons(ICONS)],
})
export class IconModule {}
