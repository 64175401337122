<div
	class="text-secondary"
	[ngClass]="{
		'ui-time-picker--disabled': formControl.disabled,
	}"
>
	<div
		class="w-full flex gap-1"
		uiDropdown
		[ignoreTrigger]="true"
		(openChange)="onDropdownStateChange($event)"
		#dropdown
	>
		<div
			class="flex bg-white items-center w-full duration-300 h-10 py-[1px] px-3 border rounded border-secondary-300 border-solid"
			[ngClass]="{
				'!border-focus': focused,
				'bg-secondary-100 text-secondary-400': formControl.disabled,
				'!border-error': !focused && formControl.invalid && formControl.touched,
				'!border-success': !focused && formControl.valid && showWhenValid && formControl.value,
			}"
			uiDropdownTrigger
		>
			<input
				type="text"
				class="w-full border-none grow outline-none overflow-hidden p-0 duration-300 placeholder:color-secondary-400 placeholder:opacity-100"
				[ngClass]="{ 'bg-secondary-100 color-secondary-400': formControl.disabled }"
				[formControl]="formControl"
				[placeholder]="placeholder"
				(focusout)="onFocusOut()"
				(focusin)="focused = true"
				[id]="'input-time-' + id"
				#inputElement
			/>
			<div>
				<span (click)="openDropdown()">
					@if (showWhenValid) {
						<!-- Failure Icon | When the input is not valid -->
						@if (formControl.invalid && formControl.value) {
							<ui-icon
								icon="failures"
								type="error"
								class="hover:cursor-pointer"
								[ngClass]="{ 'hover:cursor-default': formControl.disabled }"
								size="s"
							>
							</ui-icon>
						}
						<!-- Success Icon | When the input is valid -->
						@if (formControl.valid && formControl.value) {
							<ui-icon
								icon="x_check"
								type="success"
								class="hover:cursor-pointer"
								[ngClass]="{ 'hover:cursor-default': formControl.disabled }"
								size="s"
							>
							</ui-icon>
						}
					} @else {
						<ui-icon
							icon="clock"
							class="hover:cursor-pointer"
							[ngClass]="{ 'hover:cursor-default': formControl.disabled }"
							size="s"
						>
						</ui-icon>
					}
				</span>
			</div>
		</div>
		<ng-container *uiDropdownContent>
			<div class="p-4 flex gap-2 justify-center items-center">
				<div class="flex gap-2 flex-col">
					<ui-icon
						icon="x_arrow_up"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('hours', 'up')"
					></ui-icon>
					<span class="select-none">
						{{ formControl.value ? formControl.value.split(':')[0] : '00' }}
					</span>
					<ui-icon
						icon="x_arrow_down"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('hours', 'down')"
					></ui-icon>
				</div>
				<span class="select-none">:</span>
				<div class="flex gap-2 flex-col">
					<ui-icon
						icon="x_arrow_up"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('minutes', 'up')"
					></ui-icon>
					<span class="select-none">
						{{ formControl.value ? formControl.value.split(':')[1] : '00' }}
					</span>
					<ui-icon
						icon="x_arrow_down"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('minutes', 'down')"
					></ui-icon>
				</div>
			</div>
		</ng-container>
	</div>

	<div
		[ngClass]="{
			'text-error': !focused && formControl.invalid && formControl.touched,
			'text-success': !focused && formControl.valid && showWhenValid && formControl.value,
		}"
	></div>
</div>
