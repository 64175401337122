import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * marks an url as safe, needed in the remote-feature when setting the url of the machine
 */
@Pipe({
	name: 'safeUrl',
	standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
	/**
	 *
	 * @param sanitizer
	 */
	constructor(private sanitizer: DomSanitizer) {}

	/**
	 * sanitizes the url and marks it as safe
	 * @param value
	 * @param args
	 */
	transform(value: any, ...args: any[]): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value);
	}
}
