import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsPipe } from './pipe-seconds.pipe';

@NgModule({
	declarations: [SecondsPipe],
	exports: [SecondsPipe],
	imports: [CommonModule],
})
export class PipeSecondsModule {}
