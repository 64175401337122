import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	Output,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { ButtonToggle, StandardSizes } from '@agilox/ui-common';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { TooltipModule } from '../tooltip/tooltip.module';
import { IconModule } from '../icon/icon.module';
import { ButtonToggleContentDirective } from './directives/button-toggle-content.directive';

@Component({
	selector: 'ui-button-toggle',
	standalone: true,
	imports: [NgClass, TooltipModule, IconModule, NgIf, NgTemplateOutlet],
	templateUrl: 'button-toggle.component.html',
	styleUrls: ['button-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ButtonToggleComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ButtonToggleComponent),
			multi: true,
		},
	],
})
export class ButtonToggleComponent implements ControlValueAccessor {
	@Input() multiple = false;
	@Output() valueChanged = new EventEmitter<any>();
	private _buttons: Array<any> = [];
	@Input() set buttons(value: Array<any>) {
		this._buttons = value;
		this.formControl.updateValueAndValidity();
		this.cdRef.markForCheck();
	}

	get buttons(): Array<any> {
		return this._buttons;
	}

	@Input() size: StandardSizes = 'l';
	@Input() fullWidth: boolean = true;
	public formControl: FormControl = new FormControl();

	//Using set to enforce unique values
	private _selectedValues = new Set<string | number>();
	private onChange: (value: any) => void = () => {};
	private onTouched: () => void = () => {};

	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	@ContentChild(ButtonToggleContentDirective) buttonToggleContentDirective:
		| ButtonToggleContentDirective
		| undefined;

	writeValue(value: any): void {
		if (this.multiple && value instanceof Array) {
			value.forEach((item) => {
				this._selectedValues.add(item);
			});
		}
		this.formControl.setValue(value);
		this.cdRef.markForCheck();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		isDisabled ? this.formControl.disable() : this.formControl.enable();
	}

	onClick(button: ButtonToggle) {
		if (this.formControl.disabled || button.disabled) {
			return;
		}
		// Boolean type guard to avoid binary logic for multiple selection
		if (this.multiple && typeof button.value !== 'boolean') {
			// Toggle the selection status of the button's value
			if (this._selectedValues.has(button.value)) {
				this._selectedValues.delete(button.value);
			} else {
				this._selectedValues.add(button.value);
			}
			// Update the form control with the selected values
			this.formControl.setValue(Array.from(this._selectedValues));
		} else {
			// For single selection, directly set the button's value as the form control value
			this.formControl.setValue(button.value);
		}
		this.valueChanged.emit(this.formControl.value);
		this.onChange(this.formControl.value);
		this.onTouched();
	}

	/**
	 * Sets the button's selected style if the value is matching
	 * @param value
	 */
	isValueSelected(value: string | number) {
		if (this.multiple) {
			return this._selectedValues.has(value);
		}
		return this.formControl.value === value;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}
		const errors: any = {};
		const allButtonsDisabled: boolean = this.buttons.every((button: any) => button.disabled);
		if (allButtonsDisabled && control.hasValidator(Validators.required)) {
			errors['allButtonsDisabled'] = true;
		}
		return errors;
	}
}
