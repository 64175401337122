import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'apps/academy/src/environments/environment';
import { MediaViewType } from '../../types/media-view.types';
import { Media } from '../../interfaces/media.interface';

@Component({
	selector: 'agilox-academy-media-list',
	templateUrl: './media-list.component.html',
	styleUrls: ['./media-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaListComponent {
	@Input() media: Media[] = [];
	@Input() viewMode: MediaViewType = 'list';

	preview(media: Media) {
		window.open(environment.baseUrl + '/' + media.pathOriginal, '_blank');
	}

	protected readonly environment = environment;
}
