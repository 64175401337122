import { MenuPoint } from '@agilox/ui-common';
import { academyMenuPoints } from './menupoint.const';

/** mobile menu points */
export const mobileMenupoints: Array<MenuPoint> = [
	academyMenuPoints.dashboard,
	{
		name: 'menu.menu',
		url: '',
		icon: 'menu_hamburger',
		submenus: [
			academyMenuPoints.trainings,
			academyMenuPoints.certificates,
			academyMenuPoints.media,
			academyMenuPoints.archive,
			academyMenuPoints.help,
			academyMenuPoints.logout,
		],
		roles: [],
	},
];
