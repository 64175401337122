<div data-cy="c-vehicles-select">
	<label class="text-secondary-600 text-xs block mt-1">{{ 'general.vehicles' | translate }}</label>
	<div uiDropdown [fullWidth]="fullDropdownWidth" (openChange)="onDropdownStateChange($event)">
		<div uiDropdownTrigger class="flex gap-0.5 w-full" [uiDropdownDisabled]="formControl.disabled">
			<div
				class="flex rounded bg-white cursor-pointer h-10 w-full border border-secondary-300 py-px px-3 items-center justify-between"
				[ngClass]="{
					'!bg-secondary-100 color-secondary-400 !cursor-not-allowed': formControl.disabled,
				}"
			>
				@if (formControl.value?.length) {
					<div class="flex justify-between w-full items-center gap-2">
						<span class="line-clamp-1">
							{{ formControl.value[0].name }} ({{ formControl.value[0].serial }}) |
							{{ formControl.value[0].union }}
						</span>

						<div class="flex items-center">
							@if (formControl.value.length > 1) {
								<ui-pill
									[value]="'+' + (formControl.value.length - 1).toString()"
									class="mr-1"
									type="secondaryOutline"
								></ui-pill>
							}
						</div>
					</div>
				} @else {
					<span class="text-secondary-400 line-clamp-1">
						{{ 'select.placeholder' | translate }}
					</span>
				}
				<ui-icon
					icon="x_arrow_down"
					class="transition block"
					[ngClass]="dropdownOpen ? 'rotate-180' : ''"
					size="m"
				></ui-icon>
			</div>
		</div>

		@if (vehicleResponse$ | async | vehicleSelect; as groups) {
			<div *uiDropdownContent class="flex flex-col">
				<ui-input
					type="search"
					class="w-full h-10"
					[formControl]="searchFormControl"
					[placeholder]="'general.search' | translate"
					styleClass="!border-0"
					#searchInput
				/>
				<div
					class="max-h-64 border-t border-secondary-300"
					[ngClass]="loading() ? 'overflow-hidden' : 'overflow-y-auto'"
					#optionsList
					(scroll)="onScroll()"
				>
					@if (groups.length === 0 && !loading()) {
						<div class="p-4 pb-2">
							<ui-notify [text]="'general.no_data' | translate"></ui-notify>
						</div>
					}
					@if (!loading()) {
						@for (group of groups; track group.uuid) {
							@let unionIsSelected = isUnionSelected(group);
							@if (unionIsSelected) {
								<ui-union-select-option
									[union]="group.name"
									[options]="group.options"
									[selectedVehicles]="(selectedVehicles$ | async) ?? []"
									(deselectAll)="onDeselectAllInUnion(group.uuid)"
									(selectAll)="onSelectAllInUnion(group.uuid)"
									(toggled)="onToggleUnion(group)"
									[toggleable]="true"
									[collapsed]="group.collapsed ?? false"
								>
								</ui-union-select-option>
							}
							@if (!group.collapsed) {
								@for (vehicle of group.options; track vehicle.value) {
									<ui-vehicle-select-option
										[option]="vehicle"
										[selected]="isVehicleSelected(vehicle.value)"
										[unionSelected]="unionIsSelected"
										(toggleItem)="onSelect($event)"
										(selectWholeUnion)="onSelectAllInUnion(group.uuid)"
										[role]="role"
									></ui-vehicle-select-option>
								}
							}
						}
					}

					@if (loading()) {
						<div class="w-full h-10 mt-1">
							<ui-spinner [inline]="true" [small]="true"></ui-spinner>
						</div>
					}
				</div>
				@if (selectedVehicles && maxSelections && maxSelections < selectedVehicles.length) {
					<div class="pl-3 pr-3 absolute bottom-10 w-full">
						<ui-notify
							[text]="'vehicle_select.max' | translate: { max: maxSelections }"
						></ui-notify>
					</div>
				}

				<div class="flex items-center border-t border-secondary-300 h-10 w-full justify-center">
					@if (selectedVehicles.length > 0) {
						<div
							class="flex items-center text-secondary-800 cursor-pointer text-sm p-2 user-select-none justify-center w-full"
							[ngClass]="loading() ? '!text-secondary-400 !cursor-not-allowed' : ''"
							(click)="onDeselectAll()"
							data-cy="c-vehicle-union-select-button-deselect-all"
						>
							{{ 'select.deselect_all' | translate }}
						</div>
					}
					<div
						class="flex items-center text-secondary-800 cursor-pointer text-sm p-2 user-select-none justify-center w-full"
						[ngClass]="{
							'!text-secondary-400 !cursor-not-allowed':
								maxSelections < selectedVehicles.length ||
								selectedVehicles.length === 0 ||
								loading(),
						}"
						(click)="onSave()"
						data-cy="c-vehicle-union-select-button-save"
					>
						{{ 'general.save' | translate }}

						<span class="ml-1">({{ selectedVehicles.length }})</span>
					</div>
				</div>
			</div>
		}
	</div>
</div>
