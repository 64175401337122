import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../interfaces/vehicle.interface';

@Pipe({
	name: 'checkSubscriptionExpireSoon',
	standalone: true,
})
export class CheckSubscriptionExpireSoonPipe implements PipeTransform {
	transform(vehicle: Vehicle | undefined): boolean {
		if (!vehicle) {
			return false;
		}
		const timeLeft = vehicle.subscription_end * 1000 - Date.now();
		const expiresSoonTimestamp = 30 * 24 * 60 * 60 * 1000; // 30 days
		const isAboutToExpire = timeLeft > 0 && timeLeft < expiresSoonTimestamp;
		return isAboutToExpire;
	}
}
