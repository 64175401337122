import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './general/guards/auth/auth.guard';
import { logoutGuard } from './general/guards/logout/logout.guard';

const routes: Routes = [
	{
		path: 'logout',
		canActivate: [logoutGuard],
		children: [],
	},
	{
		path: 'dashboard',
		loadChildren: () =>
			import('apps/academy/src/app/modules/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
		canActivate: [authGuard],
	},
	{
		path: 'certificates',
		loadComponent: () =>
			import('apps/academy/src/app/modules/certificates/certificates.component').then(
				(m) => m.CertificatesComponent
			),
		canActivate: [authGuard],
	},
	{
		path: 'media',
		loadComponent: () =>
			import('apps/academy/src/app/modules/media/media.component').then((m) => m.MediaComponent),
		canActivate: [authGuard],
	},
	{
		path: 'archive',
		loadChildren: () =>
			import('apps/academy/src/app/modules/archive/archive.module').then((m) => m.ArchiveModule),
		canActivate: [authGuard],
	},
	{
		path: 'trainings',
		loadComponent: () =>
			import('apps/academy/src/app/modules/trainings/trainings.component').then(
				(m) => m.TrainingsComponent
			),
		canActivate: [authGuard],
	},
	{
		path: 'training/:id',
		loadComponent: () =>
			import('apps/academy/src/app/modules/training/training.component').then(
				(m) => m.TrainingComponent
			),
		canActivate: [authGuard],
	},
	{
		path: 'lesson/:id',
		loadChildren: () =>
			import('./modules/archive-lesson/archive-lesson.module').then((m) => m.ArchiveLessonModule),
		canActivate: [authGuard],
	},
	{
		path: 'course/:id',
		loadChildren: () => import('./modules/course/course.module').then((m) => m.CourseModule),
		canActivate: [authGuard],
	},
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

/**
 * routing-module
 */
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
