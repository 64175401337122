export enum LifecycleColors {
	PRIMARY = '#3DB6AE',
	PRIMARY900 = '#31928B',
	PRIMARY800 = '#37A49D',
	PRIMARY700 = '#3DB6AE',
	PRIMARY600 = '#64C5BE',
	PRIMARY500 = '#81D0CA',
	PRIMARY400 = '#9EDBD7',
	PRIMARY300 = '#BBE5E3',
	PRIMARY200 = '#D8F0EF',
	PRIMARY100 = '#ECF8F7',
}
