<div class="ui-sub-nav" [style.top]="positionTop" #subNav>
	<div class="ui-sub-nav__title">
		<ui-headline type="h3" class="ui-sub-nav__headline ui-sub-nav__headline--{{ navColor }}">{{
			menuItem?.name ?? '' | translate
		}}</ui-headline>
	</div>
	<div
		class="ui-sub-nav__subItems"
		directivePageOverflow
		[parentElement]="subNav"
		[changes]="menuItemChanged$ | async"
	>
		<ng-container *ngIf="menuItem && menuItem.submenus">
			<div
				class="ui-sub-nav__subItem ui-sub-nav__subItem--{{ navColor }}"
				[class.is-active]="
					(currentUrl.includes(subMenu.url) && subMenu.url !== '') || subMenu.active
				"
				*ngFor="let subMenu of menuItem.submenus"
				(click)="clickItem(subMenu)"
				[attr.data-cy]="'subnav-item-menu.' + subMenu.name"
			>
				@if (subMenu.icon) {
					<ui-icon [icon]="subMenu.icon" size="s" [type]="navColor"> </ui-icon>
				}
				{{ subMenu.name | translate }}
			</div>
		</ng-container>
	</div>
</div>
