import {
	AcademyRoutes,
	MenuPointService,
	MyRoutes,
	NavVariant,
	parseRedirectUrl,
	Role,
	User,
} from '@agilox/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'apps/academy/src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { MenuPointUrl } from '../enums/menupoint-url.enum';
import { UserSettingsService } from './user-settings.service';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuPoint } from '@agilox/ui-common';

/**
 * provides the authentication functionality
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	/** environment */
	private _environment = 'live';

	/** redirect url */
	private redirectUrl: string | undefined;

	set environment(windowUrl: string) {
		const env = Object.keys(AcademyRoutes).find((key) =>
			windowUrl.includes(AcademyRoutes[key as keyof typeof AcademyRoutes])
		);
		if (env) {
			this._environment = env;
		}
	}
	get environment(): string {
		return this._environment;
	}

	/** refresh requests */
	refreshRequests: boolean = false;

	/**
	 * indicates if the user is logged in
	 */
	private _isLoggedIn = false;
	set isLoggedIn(val: boolean) {
		this._isLoggedIn = val;
		if (this._isLoggedIn) {
			this.router.navigateByUrl(this.redirectUrl || '');
		} else {
			this.userSettings.clearLocalStorage();
			window.location.href =
				MyRoutes[this._environment as keyof typeof MyRoutes] +
				'/#/login?app=academy' +
				(this.redirectUrl ? '&redirect=' + this.redirectUrl : '');
		}
	}

	get isLoggedIn(): boolean {
		return this._isLoggedIn;
	}

	/** subscription for the getUser */
	private getUserSubscription: Subscription | undefined;

	constructor(
		private http: HttpClient,
		private router: Router,
		private userSettings: UserSettingsService,
		private menuPointService: MenuPointService
	) {
		this.redirectUrl = window.location.href.split('#')[1];
		this.environment = window.location.href;

		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter((event): event is NavigationStart => event instanceof NavigationStart)
			)
			.subscribe((event: NavigationStart) => {
				this.redirectUrl = parseRedirectUrl(event.url);
			});
	}

	/**
	 * logs the user out
	 */
	logout() {
		const subscription = this.http.get(environment.agiloxApi + '/v2/User/logout').subscribe(() => {
			this.redirectUrl = '';
			this.isLoggedIn = false;
			subscription?.unsubscribe();
			window.location.href = MyRoutes[this._environment as keyof typeof MyRoutes];
		});
	}

	/**
	 * get user data and checks if logged in
	 */
	getUser(): Observable<any> {
		const subject = new Subject();
		this.getUserSubscription?.unsubscribe();
		this.getUserSubscription = this.http
			.get(environment.agiloxApi + '/v2/User/getUserInfo', {})
			.subscribe((data: any) => {
				this.isLoggedIn = data.status;
				if (data.status) {
					this.userSettings.setFromResponse(data);
				}
				subject.complete();
			});
		return subject.asObservable();
	}

	/**
	 * checks if user is allowed to access this url
	 * @param url url
	 * @param user
	 */
	isUserPermitted(url: string, user: User) {
		if (!user) {
			return false;
		}
		const menupoints = this.menuPointService.getMenuPointsByCategory(
			NavVariant.DESKTOP,
			Role[user.role as keyof typeof Role]
		);

		return menupoints.some(
			(menuPoint: MenuPoint) =>
				menuPoint.url === url || menuPoint.submenus?.some((submenu) => submenu.url === url)
		);
	}

	/**
	 * returns if url is menu url
	 * @param url url
	 */
	isMenuUrl(url: string) {
		const preparedUrl = url.split('/');
		return Object.keys(MenuPointUrl)
			.map((key) => MenuPointUrl[key as keyof typeof MenuPointUrl].toString().replace('/', ''))
			.includes(preparedUrl[preparedUrl.length - 1].split('?')[0]);
	}
}
