import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, HeadlineComponent } from '@agilox/ui';

@Component({
	selector: 'ui-confirmation-content',
	standalone: true,
	imports: [HeadlineComponent, ButtonComponent, TranslateModule],
	templateUrl: './confirmation-content.component.html',
	styleUrl: './confirmation-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationContentComponent {
	@Input() bodyText: string = '';
	@Input() submitButtonText: string = '';

	@Output() confirmed: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancelled: EventEmitter<void> = new EventEmitter<void>();
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	onSubmit() {
		this.confirmed.emit();
		this.close();
	}

	onCancel() {
		this.cancelled.emit();
		this.close();
	}

	private close() {
		this.closed.emit();
	}
}
