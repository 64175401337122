export enum AnalyticsColors {
	PRIMARY = '#9c3',
	PRIMARY900 = '#82AD2B',
	PRIMARY800 = '#8EBD2F',
	PRIMARY700 = '#9c3',
	PRIMARY600 = '#A8D452',
	PRIMARY500 = '#B8DB70',
	PRIMARY400 = '#C7E38F',
	PRIMARY300 = '#D6EBAD',
	PRIMARY200 = '#E5F2CC',
	PRIMARY100 = '#F5FAEB',
}
