import { OnboardingAction, OnboardingInterface } from '@agilox/common';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../button/button.component';
import { HeadlineComponent } from '../../../headline/headline.component';
import { GetOnboardingImagePipe } from './get-onboarding-image.pipe';

@Component({
	selector: 'ui-onboarding-slide',
	templateUrl: './onboarding-slide.component.html',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		ButtonComponent,
		HeadlineComponent,
		GetOnboardingImagePipe,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSlideComponent {
	@Input() slide!: OnboardingInterface;
	@Output() buttonClicked: EventEmitter<OnboardingAction> = new EventEmitter<OnboardingAction>();

	onButtonClicked(action: OnboardingAction): void {
		this.buttonClicked.emit(action);
	}
}
