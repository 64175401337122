import { LOCALE_ID, Provider } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEnUs from '@angular/common/locales/en';
import localeEnGb from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';

/**
 * This provides the locale for the date and number pipes.
 * It detects the browser's locale and registers the appropriate locale data.
 *
 * We only use en-US if the browser's locale is explicitly set to en-US (This is because of the different date formats).
 * en-GB for example also uses the dd/MM/yyyy format, so we don't need to register it.
 */
export const localeProvider: Provider = {
	provide: LOCALE_ID,
	useFactory: () => {
		const browserLocale = navigator.language.split('-')[0] || 'de'; // Default to 'de' if no locale detected

		// Register locales based on the detected browser locale
		if (browserLocale === 'de') {
			registerLocaleData(localeDe); // Register German locale
			return 'de'; // Return 'de' for Germany
		} else if (browserLocale === 'en' && navigator.language === 'en-US') {
			// Check if the browser is explicitly set to en-US (for the USA)
			registerLocaleData(localeEnUs); // Register en-US locale
			return 'en-US';
		} else {
			registerLocaleData(localeDe); // Register German locale
			return 'de'; // Return 'de' for German
		}
	},
};
