import { Pipe, PipeTransform } from '@angular/core';
import { CertificateService } from '../../modules/certificates/certificate.service';
import { Certificate } from '../interfaces/certificate.interface';
import { CertificateState } from '../enums/certificate-state.enum';

@Pipe({
	name: 'certificateTagType',
})
export class CertificateTagTypePipe implements PipeTransform {
	constructor(private certificateService: CertificateService) {}
	transform(certificate: Certificate | null): string | undefined {
		if (certificate) {
			if (!certificate.state) {
				certificate.state = this.certificateService.getCertificateState(certificate);
			}
			return certificate.state === CertificateState.EXPIRED
				? 'alert'
				: certificate.state === CertificateState.EXPIRE_SOON
					? 'warning'
					: 'primaryLight';
		}
		return undefined;
	}
}
