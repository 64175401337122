@if (toggleable) {
	<div
		class="flex h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
	>
		<ng-container *ngTemplateOutlet="checkbox"></ng-container>
		<div class="-ml-1 flex gap-2 items-center flex-grow" (click)="onToggle()">
			<ui-icon
				icon="x_arrow_down"
				class="transition block"
				[ngClass]="collapsed ? 'rotate-180' : ''"
				size="m"
			></ui-icon>
			<span class="select-none font-semibold text-primary-900">{{ union | empty }}</span>
		</div>
	</div>
} @else {
	<div
		class="flex h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
		(click)="handleClick()"
	>
		<ng-container *ngTemplateOutlet="checkbox"></ng-container>

		<span class="select-none font-semibold text-primary-900 -ml-2">{{ union | empty }}</span>
	</div>
}

<ng-template #checkbox>
	<ui-checkbox
		[icon]="union | vehicleSelectUnionCheckbox: options : selectedVehicles"
		[checked]="true"
		class="mr-4 -mt-[1.625rem]"
		(click)="handleCheckboxClick($event)"
	></ui-checkbox>
</ng-template>
