<div
	class="ui-select-button"
	[ngClass]="disabled ? 'ui-select-button--disabled' : ''"
	(click)="click($event)"
>
	<ui-icon class="ui-select-button__icon" *ngIf="icon" [icon]="icon"></ui-icon>
	<span class="ui-select-button__label">
		{{ label }}
	</span>
</div>
