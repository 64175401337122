import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppError } from '../interfaces/app-error.interface';

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	private errorSubject = new BehaviorSubject<AppError | null>(null);
	error$ = this.errorSubject.asObservable();

	setError(data: AppError) {
		this.errorSubject.next(data);
	}

	clearError() {
		this.errorSubject.next(null);
	}
}
