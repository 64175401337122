import { Unsubscribe } from '@agilox/common';
import { InputType } from '@agilox/ui-common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'ui-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
})
export class InputComponent extends Unsubscribe implements ControlValueAccessor, AfterViewInit {
	@Input() type: InputType = 'text';
	/**
	 * Will show a checkmark icon and color the border in green
	 * if the input is valid.
	 */
	@Input() showWhenValid: boolean = false;
	@Input() placeholder: string = '';
	@Input() hideClearIcon: boolean = false;
	@Input() inputId: string = '';
	@Input() styleClass: string = '';

	/**
	 * If the input type is password, this property will be used
	 * to toggle the visibility of the password
	 */
	passwordVisible: boolean = false;

	/**
	 * Will output anytime the input changes.
	 * It is also possible to subscribe to the value changes on the formControl
	 */
	@Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
	public formControl: FormControl = new FormControl();

	focused: boolean = false;

	@ViewChild('inputElement') input: ElementRef | undefined;

	/**
	 * Should only be used when the parent is using ngModel and not
	 * formControl.
	 * It should be handled via the formControl if applicable!
	 * @param dis
	 */
	@Input() set disabled(dis: boolean) {
		this.setDisabledState(dis);
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	/**
	 * This is sadly needed because we have no way of knowing if
	 * the formControl has been marked as touched or not by the parent.
	 * See: https://github.com/angular/angular/issues/45089
	 * @param value
	 */
	@Input() set touched(value: boolean) {
		if (value) {
			this.formControl.markAsTouched();
			return;
		}
		this.formControl.markAsUntouched();
	}

	constructor() {
		super();
		this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
			this.onChange(value);
			this.onTouched();
			this.valueChanged.emit(value);
		});
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.formControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
	}

	writeValue(value: any): void {
		if (this.formControl && this.formControl.value !== value) {
			this.formControl.setValue(value, { emitEvent: false });
			this.cdRef.markForCheck();
		}
	}

	setDisabledState(isDisabled: boolean) {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });
		this.cdRef.markForCheck();
	}

	/**
	 * Clears the input field
	 * and sets the focus to the input because the clear icon
	 * is outside of the input
	 */
	clearInput($event: any) {
		$event.preventDefault();
		this.formControl.reset('');
		this.setFocus();
	}

	toggleVisibility() {
		this.passwordVisible = !this.passwordVisible;
		this.setFocus();
	}

	/**
	 * Do not delete, is called automatically by the form
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		return null;
	}

	onFocusOut() {
		this.focused = false;
		/** Force the sanitization of the value */
		this.writeValue(this.formControl.value);
	}

	setFocus() {
		if (this.input?.nativeElement) {
			this.input.nativeElement.focus();
		}
	}

	onFocusIn() {
		if (!this.formControl.invalid && !this.formControl.disabled) {
			this.focused = true;
		}
	}

	onNumberButtonClick(upOrDown: 'up' | 'down') {
		this.setFocus();
		if (this.formControl.value === null) {
			this.formControl.setValue(0);
			return;
		}
		this.formControl.setValue(parseInt(this.formControl.value) + (upOrDown === 'up' ? 1 : -1));
	}

	/**
	 * Needed in order to correctly display
	 * invalid input fields when the form is initialized
	 */
	ngAfterViewInit() {
		this.cdRef.markForCheck();
	}
}
