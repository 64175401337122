import { MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { MenuPointAction } from '@agilox/common';

@Component({
	selector: 'ui-sub-nav',
	templateUrl: './sub-nav.component.html',
	styleUrls: ['./sub-nav.component.scss'],
})
export class SubNavComponent implements OnInit, OnChanges {
	private router: Router = inject(Router);
	public cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
	@Input() menuItem: MenuPoint | undefined;
	@Input() navColor: NavColor = NavColors.Dark;

	@Output() itemClick: EventEmitter<MenuPointAction> = new EventEmitter<MenuPointAction>();

	positionTop: string = '0px';

	currentUrl: string = '';

	/**
	 * Notifies when the menu item changes
	 * Needed for the page overflow directive
	 */
	menuItemChanged: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
	menuItemChanged$: Observable<any> = this.menuItemChanged.asObservable();

	constructor() {
		this.currentUrl = this.router.url;
		this.router.events
			.pipe(
				takeUntilDestroyed(),
				filter((event) => event instanceof NavigationEnd)
			)
			.subscribe((event: any) => {
				this.currentUrl = event.urlAfterRedirects;
				this.cdRef.markForCheck();
			});
	}

	ngOnInit() {
		this.getPosition();
	}

	getPosition() {
		if (!this.menuItem) {
			return;
		}

		let el = document.querySelector('[data-id="' + this.menuItem.url + '"]');
		if (el) {
			this.positionTop = (el.getBoundingClientRect().top - window.scrollY).toString() + 'px';
			this.cdRef.markForCheck();
			this.menuItemChanged.next(this.positionTop);
		}
	}

	clickItem(item: MenuPoint) {
		this.itemClick.emit({ click: false, menuPoint: item });
	}

	ngOnChanges() {
		this.getPosition();
	}
}
