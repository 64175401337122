import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { dateToString } from '@agilox/common';
import { CalendarWeekOutput } from '@agilox/ui-common';

/**
 * This component wraps the navigation and the calendar
 * Is used both by the INLINE and the INPUT datepicker variants
 */
@Component({
	selector: 'ui-datepicker-base',
	templateUrl: './datepicker-base.component.html',
	styleUrls: ['./datepicker-base.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerBaseComponent {
	@Input({ required: true }) set currentlyDisplayedMonth(value: Date) {
		if (this.minDate && value < this.minDate) {
			value = new Date(this.minDate);
		}
		if (this.maxDate && value > this.maxDate) {
			value = new Date(this.maxDate);
		}
		this._currentlyDisplayedMonth = value;
	}

	private _currentlyDisplayedMonth: Date = new Date();
	get currentlyDisplayedMonth(): Date {
		return this._currentlyDisplayedMonth;
	}

	@Input() minDate: Date | undefined;
	@Input() maxDate: Date | undefined;
	@Input() disabled: boolean = true;
	@Input() selectedDate: string | null = null;
	@Input() syncedDate: Date | undefined;

	@Output() dateSelectionChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() navDateChange: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	public onTodayClick(): void {
		const today = new Date();
		this.currentlyDisplayedMonth = today;
		this.dateSelectionChange.emit(dateToString(today));
	}
}
