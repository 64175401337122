import { environment } from '@academy/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CertificateState } from '../../general/enums/certificate-state.enum';
import { Certificate } from '../../general/interfaces/certificate.interface';

// expire soon should be displayed one month before the certificate expires
const EXPIRE_SOON = 30 * 86460;

@Injectable()
export class CertificateService {
	constructor(private http: HttpClient) {}

	getCertificates(): Observable<Certificate[]> {
		return this.http.get<Certificate[]>(environment.server + '/certificates').pipe(
			map((certificates: Certificate[]) => {
				if (certificates.length) {
					return certificates.map((certificate) => {
						certificate.state = this.getCertificateState(certificate);
						return certificate;
					});
				}
				return [];
			})
		);
	}

	getCertificateState(certificate: Certificate): CertificateState {
		if (certificate.expires === 0) {
			return CertificateState.VALID_UNLIMITED;
		}
		if (certificate.expires < Date.now() / 1000) {
			return CertificateState.EXPIRED;
		}
		if (certificate.expires - Date.now() / 1000 < EXPIRE_SOON) {
			return CertificateState.EXPIRE_SOON;
		}
		return CertificateState.VALID;
	}
}
