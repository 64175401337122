import { Pipe, PipeTransform } from '@angular/core';

/**
 * transforms seconds to hh:mm:ss
 */
@Pipe({
	name: 'seconds',
})
export class SecondsPipe implements PipeTransform {
	/**
	 * transforms seconds to HH:mm:ss
	 * @param value amount of seconds
	 * @param args nothing should be given
	 */
	transform(value: any, showUnits = false, showDays = false, ...args: any[]): any {
		if (value === undefined) {
			return '';
		}

		const seconds = Math.floor(value);
		const h = 3600;
		const m = 60;
		const d = 86400;
		const days = Math.floor(seconds / d).toString();
		let hours = Math.floor(seconds / h).toString();
		let minutes = Math.floor((seconds % h) / m).toString();
		let scnds = Math.floor(seconds % m).toString();
		if (scnds.length < 2) {
			scnds = '0' + scnds;
		}
		if (minutes.length < 2) {
			minutes = '0' + minutes;
		}
		if (showDays) {
			hours = Math.floor((seconds % d) / h).toString();
			if (hours.length < 2) {
				hours = '0' + hours;
			}
			return showUnits
				? days + 'd ' + hours + 'h ' + minutes + 'm'
				: days + ':' + hours + ':' + minutes;
		} else if (showUnits) {
			if (hours.length < 2) {
				hours = '0' + hours;
			}
			return hours + 'h ' + minutes + 'm ' + scnds + 's';
		} else {
			return hours + ':' + minutes + ':' + scnds;
		}
	}
}
