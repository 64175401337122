import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	effect,
	EventEmitter,
	inject,
	input,
	Input,
	InputSignal,
	OnInit,
	Output,
} from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ui-datepicker-navigation',
	templateUrl: './datepicker-navigation.component.html',
	styleUrls: ['./datepicker-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerNavigationComponent implements OnInit {
	currentlyDisplayMonth: InputSignal<Date> = input.required<Date>();
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
	private translate: TranslateService = inject(TranslateService);

	@Input() minDate: Date | undefined = undefined;
	@Input() maxDate: Date | undefined = undefined;

	@Input() set disabled(value: boolean) {
		this._disabled = value;
		this._disabled ? this.formGroup.disable() : this.formGroup.enable();
	}
	get disabled(): boolean {
		return this._disabled;
	}
	private _disabled: boolean = false;

	previousMonthDisabled: boolean = false;
	nextMonthDisabled: boolean = false;

	yearSelectOptions: SelectOption<number>[] = [];
	monthSelectOptions: SelectOption<number>[] = this.generateMonthOptions();

	formGroup = new FormGroup({
		month: new FormControl(),
		year: new FormControl(),
	});

	@Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

	@Output() todayClick: EventEmitter<void> = new EventEmitter<void>();

	constructor() {
		effect(() => {
			if (this.currentlyDisplayMonth()) {
				this.setFormValues(this.currentlyDisplayMonth());
				this.updateNavigation();
			}
		});
	}

	private setFormValues(date: Date) {
		// Check if date is not already set
		if (
			this.formGroup.value.month !== date.getMonth() + 1 ||
			this.formGroup.value.year !== date.getFullYear()
		) {
			this.formGroup.setValue(
				{
					month: date.getMonth() + 1,
					year: date.getFullYear(),
				},
				{ emitEvent: false }
			);
		}
		this.cdRef.markForCheck();
	}

	ngOnInit() {
		this.formGroup.valueChanges.subscribe((value) => {
			if (value.month && value.year) {
				this.dateChange.emit(new Date(value.year, value.month - 1));
				this.updateNavigation();
			}
		});

		this.yearSelectOptions = this.generateYearOptions(
			this.minDate ? this.minDate.getFullYear() : 1900,
			this.maxDate ? this.maxDate.getFullYear() : new Date().getFullYear() + 50
		);
	}

	goToPreviousMonth() {
		const date = this.currentlyDisplayMonth();
		date.setMonth(date.getMonth() - 1);
		this.setFormValues(date);
	}

	goToNextMonth() {
		const date = this.currentlyDisplayMonth();
		date.setMonth(date.getMonth() + 1);
		this.setFormValues(date);
	}

	updateNavigation() {
		this.previousMonthDisabled = false;
		this.nextMonthDisabled = false;

		const date: Date = this.currentlyDisplayMonth();
		const currentYear: number = date.getFullYear();
		const currentMonth: number = date.getMonth() + 1;
		const minYear: number | null = this.minDate ? this.minDate.getFullYear() : null;

		// Check if the current month is the minDate
		if (this.minDate && currentYear === minYear && currentMonth === this.minDate.getMonth() + 1) {
			this.previousMonthDisabled = true;
		}

		// Check if the current month is the maxDate
		if (
			this.maxDate &&
			currentYear === this.maxDate.getFullYear() &&
			currentMonth === this.maxDate.getMonth() + 1
		) {
			this.nextMonthDisabled = true;
		}
	}

	private generateYearOptions(minYear: number, maxYear: number): SelectOption<number>[] {
		const yearOptions: SelectOption<number>[] = [];
		for (let i = minYear; i <= maxYear; i++) {
			yearOptions.push({ value: i, title: i.toString() });
		}
		return yearOptions;
	}

	private generateMonthOptions(): SelectOption<number>[] {
		return Array(12)
			.fill(0)
			.map((_, index) => {
				return {
					value: index + 1,
					title: this.translate.instant('datepicker.months.' + index.toString()),
				};
			});
	}
}
