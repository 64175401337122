@if (lesson) {
	<div class="c-lesson" [attr.data-cy]="'lesson-' + lesson.id">
		@if (lesson.title) {
			<ui-headline class="c-lesson__headline" [type]="headlineType">{{ lesson.title }}</ui-headline>
		}

		<div class="c-lesson__content">
			@if (lesson.text) {
				<div class="c-lesson__text" [innerHtml]="lesson.text | trusted"></div>
			}
			@if (lesson.video) {
				<ui-video
					[id]="lesson.video"
					[playbarVisible]="lesson.videoAlreadyWatched || !enforcedLessonOrder"
					(videoFinished)="onVideoFinished(lesson.id)"
					class="c-lesson__video"
				></ui-video>
			}
			@if (lesson.text2) {
				<div class="c-lesson__text" [innerHtml]="lesson.text2 | trusted"></div>
			}
			@if (lesson.pictures) {
				<div class="c-lesson__boxes">
					@for (picture of lesson.pictures; track picture) {
						{{ baseUrl }}/{{ picture.pathOriginal }}
						<img
							[alt]="picture.title"
							class="c-lesson__picture"
							[src]="baseUrl + '/' + picture.pathOriginal"
						/>
					}
				</div>
			}

			@if (lesson.files) {
				<ui-headline type="h4" *ngIf="lesson.files?.length"
					>{{ 'course.downloads' | translate }}
				</ui-headline>
				<agilox-academy-media-list [media]="lesson.files"></agilox-academy-media-list>
			}
		</div>
	</div>
}
