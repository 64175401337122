import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotifyIcons, Sizes, State, States } from '@agilox/ui-common';
import { IconModule } from '../icon/icon.module';
import { NgClass } from '@angular/common';

@Component({
	selector: 'ui-notify',
	standalone: true,
	imports: [IconModule, NgClass],
	templateUrl: './notify.component.html',
	styleUrls: ['./notify.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyComponent {
	@Input() type: State = States.Info;
	@Input() text: string = '';
	@Input() closable: boolean = false;
	@Output() closeNotify = new EventEmitter<void>();

	public notifyIcons = NotifyIcons;
	public sizes = Sizes;
}
