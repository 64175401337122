import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'getOnboardingImage',
	standalone: true,
})
export class GetOnboardingImagePipe implements PipeTransform {
	private translate = inject(TranslateService);

	transform(image: { en: string; de: string }): string {
		const lang = this.translate.currentLang || this.translate.defaultLang || 'en';
		return image[lang as keyof typeof image] || image['en'];
	}
}
