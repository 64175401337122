<div
	class="flex gap-2 h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
	[ngClass]="{
		'bg-primary-200': selected,
		'text-secondary-400': option.isDisabled,
		'!cursor-not-allowed': option.isDisabled,
		'hover:bg-white': option.isDisabled,
		'pl-9': !option.isDisabled && unionSelected,
	}"
	(click)="toggleItemHandler(option)"
	data-cy="c-vehicle-union-select-select-option"
>
	<ui-checkbox
		[disabled]="option.isDisabled || false"
		[checked]="selected"
		(checkedChange)="toggleItemHandler(option)"
		class="mr-4 -mt-[1.625rem]"
	></ui-checkbox>
	<div class="flex items-center justify-between -ml-4 w-full gap-2">
		<div class="flex items-center gap-1 max-w-full">
			@if (!option.value?.active) {
				<ui-icon
					icon="decommissioned"
					[uiTooltip]="option.isDisabled ? '' : ('general.decommissioned' | translate)"
				></ui-icon>
			}
			@if (option.value | checkSubscriptionExpireSoon) {
				<ui-icon
					icon="failures"
					[uiTooltip]="
						'general.subscription_for_vehicle_expire_soon'
							| translate
								: {
										machines: option.value?.name || '-',
										date: option.value?.subscription_end * 1000 | date: 'shortDate',
								  }
					"
				></ui-icon>
			}
			<div class="flex whitespace-nowrap max-w-full">
				<div
					class="max-w-full flex items-center gap-1"
					[uiTooltip]="option.isDisabled ? '' : (option.value | vehicleOptionTooltip)"
				>
					<span>{{ option.value?.name }}</span>
					<span class="text-xs">({{ option.value?.serial }})</span>
					<span> | </span>
				</div>
				<div
					[ngClass]="option.isDisabled ? 'text-secondary-400' : 'text-primary-900'"
					[uiTooltip]="option.isDisabled ? '' : ('general.select_whole_union' | translate)"
					(click)="onSelectWholeUnion(option, $event)"
				>
					{{ option.value?.union | empty }}
				</div>

				<div
					class="hidden md:block line-clamp-1 text-ellipsis"
					[ngClass]="{ '!hidden': role === Role.customer }"
					[uiTooltip]="option.isDisabled ? '' : (option.value | vehicleOptionTooltip)"
				>
					| {{ option.value?.customer }}
				</div>
			</div>
		</div>
		@if (displayPills) {
			<div class="flex gap-1">
				<!-- if decommissioned, then only this pill should be displayed -->
				@if (!option.value?.active) {
					<ui-pill [value]="'general.decommissioned' | translate"></ui-pill>
				} @else {
					@if (option.value?.maas) {
						<ui-pill [value]="'general.maas' | translate"></ui-pill>
					}

					@let subscriptionRemaining = getSubscriptionRemaining(option.value?.subscription_end);

					@if (subscriptionRemaining <= 0) {
						<ui-pill [value]="'subscription.expired' | translate"></ui-pill>
					} @else {
						<ui-pill
							[value]="
								subscriptionRemaining
									| pluralTranslate
										: 'subscription.remaining_day'
										: 'subscription.remaining_days'
										: { remaining: subscriptionRemaining }
							"
							type="secondaryOutline"
						></ui-pill>
					}
				}
			</div>
		}
	</div>
</div>
