import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { ToolbarItem } from '../interfaces/toolbar-item.interface';
import { ToolBarItems } from '../consts/toolbar-items.const';
import Quill from 'quill';

@Component({
	selector: 'ui-rich-text-editor-toolbar',
	templateUrl: './rich-text-editor-toolbar.component.html',
	styleUrls: ['./rich-text-editor-toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class RichTextEditorToolbarComponent implements OnInit {
	public elementRef: ElementRef = inject(ElementRef);
	toolbarItems: ToolbarItem[] = ToolBarItems;

	@Input() id: string = '';

	@Output() undo: EventEmitter<void> = new EventEmitter<void>();
	@Output() redo: EventEmitter<void> = new EventEmitter<void>();

	ngOnInit() {
		this.setupIcons();
	}

	/**
	 * Overwrite the default icons with our custom ones
	 */
	setupIcons() {
		const icons = Quill.import('ui/icons') as any;
		this.toolbarItems.forEach((item: ToolbarItem) => {
			if (item.icon) {
				if (!icons[item.class]) {
					icons[item.class] = {}; // Initialize the object if it doesn't exist
				}
				if (item.value) {
					icons[item.class][item.value] = item.icon;
				} else {
					icons[item.class] = item.icon;
				}
			}
		});
	}

	onUndo() {
		this.undo.emit();
	}

	onRedo() {
		this.redo.emit();
	}
}
