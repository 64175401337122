import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Breadcrumb } from '@agilox/ui-common';
import { Router } from '@angular/router';
import { IconModule } from '../icon/icon.module';
import { BreadcrumbEllipsisPipe } from './pipes/breadcrumb-ellipsis.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'ui-breadcrumb',
	standalone: true,
	imports: [IconModule, BreadcrumbEllipsisPipe, TranslateModule, NgClass],
	templateUrl: './breadcrumbs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
	/**
	 * The icon to display in front of the first label
	 */
	@Input() icon: string = '';

	/**
	 * The breadcrumbs to display
	 */
	@Input() breadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();

	/**
	 * The amount of breadcrumbs to display
	 *
	 * if the amount of breadcrumbs is higher than the maxBreadcrumbs
	 * only the following breadcrumbs will be displayed:
	 * 1. first breadcrumb
	 * 2. breadcrumbs in between the range of maxBreadcrumbs to length of the array of breadcrumbs
	 *
	 * @default 4
	 * @min 1
	 * @example maxBreadcrumbs = 2
	 * breadcrumbs = [1,2,3,4,5,6,7,8,9,10]
	 * following breadcrumbs will be displayed:
	 * 1, ... , 8, 9, 10
	 */
	_maxBreadcrumbs: number = 4;
	@Input() set maxBreadcrumbs(max: number) {
		this._maxBreadcrumbs = max < 1 ? 1 : max;
	}

	/**
	 * The state of the ellipsis
	 * @default false
	 * Only in use when the amount of breadcrumbs is higher than the maxBreadcrumbs
	 */
	expanded: boolean = false;

	private router: Router = inject(Router);

	/**
	 * Navigates to the link of the breadcrumb
	 * @param breadcrumb
	 */
	doRoute(breadcrumb: Breadcrumb) {
		if (this.breadcrumbs.indexOf(breadcrumb) === this.breadcrumbs.length - 1) {
			return;
		}
		this.router.navigateByUrl(breadcrumb.link);
	}
}
