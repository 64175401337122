/**
 * Order here has to be different than the other apps
 * We don't have test/beta/stage for selftest.
 * This causes an issue when running it on live as it will grab
 * the first match in the routes, which previously was stage. This breaks
 * the app-nav routing. To circumvent this issue Live must be the first entry,
 *  with the non-local url
 */
export const SelftestRoutes = {
	Local: 'http://localhost:1400',
	LocalLive: 'http://localhost:1400',
	Cypress: 'http://localhost:1400',
	Live: 'https://selftest.agilox.net',
	Test: 'https://selftest.agilox.net',
	Beta: 'https://selftest.agilox.net',
	Stage: 'https://selftest.agilox.net',
} as const;
