import {
	ButtonComponent,
	DropdownDirective,
	DropdownModule,
	IconModule,
	TooltipModule,
} from '@agilox/ui';
import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	ValidationErrors,
} from '@angular/forms';
import { filter, map } from 'rxjs';

@Component({
	selector: 'ui-time-picker',
	templateUrl: './time-picker.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => TimePickerComponent), multi: true },
	],
	imports: [
		ButtonComponent,
		IconModule,
		ReactiveFormsModule,
		TooltipModule,
		DropdownModule,
		NgClass,
	],
})
export class TimePickerComponent implements ControlValueAccessor, OnInit {
	/**
	 * Will show a checkmark icon and color the border in green
	 * if the input is valid.
	 */
	@Input() showWhenValid: boolean = false;
	@Input() placeholder: string = '';
	@Input() id: string = '';

	@Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
	public formControl: FormControl = new FormControl();

	focused: boolean = false;

	@ViewChild('inputElement') input: ElementRef | undefined;
	@ViewChild('dropdown', { read: DropdownDirective }) dropdown: DropdownDirective | undefined;

	private destroyRef: DestroyRef = inject(DestroyRef);

	onChange: any = () => {};

	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnInit() {
		this.formControl.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				map(this.doMask),
				map((value: string | null) => this.validateAndParseTime(value)),
				filter((value: string | null): value is string => value !== null)
			)
			.subscribe((value: string) => {
				this.formControl.setValue(value, { emitEvent: false });
				this.onChange(value);
				this.onTouched();
				this.valueChanged.emit(value);
			});
	}

	writeValue(value: any): void {
		if (this.formControl.disabled) {
			return;
		}

		if (!this.isInputTimeValid(value)) {
			throw new TypeError('Invalid time input. Expected format: HH:MM');
		}
		this.formControl.setValue(value, { emitEvent: false });
	}

	setDisabledState(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });
	}

	onFocusOut() {
		this.focused = false;
		this.autoFormatTime();
	}

	/**
	 * Auto formats the time input
	 * Cases:
	 * 	1 Character: 0H -> HH:00
	 * 	2 Characters: HH -> HH:00
	 * 	3 Characters: HH: -> HH:M0
	 * 	3 Characters but Character > 5 -> HH:0M
	 * @private
	 */
	private autoFormatTime() {
		if (!this.formControl.value) {
			return;
		}
		// if value contains any non digit characters, return
		if (/[^\d:]/.test(this.formControl.value)) {
			return;
		}

		const val = this.formControl.value.split('').filter((c: string) => c !== ':');
		if (!val.length) {
			return;
		}
		switch (val.length) {
			case 1:
				this.formControl.setValue(`0${val}:00`);
				break;
			case 2:
				this.formControl.setValue(`${val}:00`);
				break;
			case 3:
				if (Number(val[2]) > 5) {
					this.formControl.setValue(`${val[0]}${val[1]}:0${val[2]}`);
				} else {
					this.formControl.setValue(`${val[0]}${val[1]}:${val[2]}0`);
				}
				break;
		}
	}

	openDropdown() {
		this.dropdown?.openDropdown();
	}

	/**
	 * Called when the dropdown state changes
	 * Needed to set the focused state of the input
	 * @param opened
	 */
	onDropdownStateChange(opened: boolean) {
		this.focused = opened;
	}

	setTimeViaArrows(type: 'hours' | 'minutes', direction: 'up' | 'down') {
		if (!this.formControl.value) {
			this.formControl.setValue('00:00', { emitEvent: false });
		}

		let [hours, minutes] = this.formControl.value.split(':').map(Number);

		if (type === 'hours') {
			hours = this.adjustValue(hours, direction, 23);
		} else {
			minutes = this.adjustValue(minutes, direction, 59);
		}

		this.formControl.setValue(`${this.pad(hours)}:${this.pad(minutes)}`);
	}

	private adjustValue(value: number, direction: 'up' | 'down', max: number): number {
		if (direction === 'up') {
			return value === max ? 0 : value + 1;
		} else {
			return value === 0 ? max : value - 1;
		}
	}

	private pad(value: number): string {
		return value.toString().padStart(2, '0');
	}

	/**
	 * Masks the input value to the format HH:MM
	 * @param value
	 * @private
	 */
	private doMask(value: string | null): string {
		if (!value) {
			return '';
		}

		/**
		 * if the value has already been masked, remove all non digit characters except ':'
		 * and return the first 5 characters
		 */
		if (value.includes(':')) {
			return value.replace(/[^\d:](?!:)/g, '').slice(0, 5);
		}

		// remove all non digit characters
		const sanitized = value.replace(/[^\d]/g, '');

		const dotPositions: number[] = [2];
		let result: string = '';
		for (let i = 0; i < sanitized.length; i++) {
			if (dotPositions.includes(i) && !(i > 0 && sanitized[i - 1] === ':')) {
				result += ':';
			}
			result += sanitized[i];
		}
		return result.slice(0, 5);
	}
	/**
	 * Validates the time input
	 * If the input is not a complete time, it will return the input as is
	 * If the input is a complete time, it will validate the hours and minutes
	 * and return a valid time
	 * @param value
	 * @private
	 */
	private validateAndParseTime(value: string | null): string | null {
		if (value?.length !== 5) {
			return null;
		}
		const [hours, minutes] = value.split(':').map(Number);

		const newHours: number = Math.min(Math.max(hours, 0), 23);
		const newMinutes: number = Math.min(Math.max(minutes, 0), 59);
		return `${this.pad(newHours)}:${this.pad(newMinutes)}`;
	}

	/**
	 * Do not delete, is called automatically by the form
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		if (control.value && !this.isInputTimeValid(control.value)) {
			return { invalidTime: true };
		}

		return null;
	}

	/**
	 * Validates the input time given via the formControl / writeValue
	 * Checks if the time is in the format HH:MM and if the hours and minutes are valid
	 * and if the value is a string or null / undefined
	 * @param value
	 * @private
	 */
	private isInputTimeValid(value: any): boolean {
		if (typeof value === 'undefined' || value === null || value === '') {
			return true;
		}

		if (typeof value !== 'string') {
			return false;
		}

		return /^\d{2}:\d{2}$/.test(value);
	}
}
