import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './component/tooltip/tooltip.component';
import { TooltipDirective } from './directive/tooltip.directive';
import { PipeTrustedModule } from '@agilox/pipe/trusted';

@NgModule({
	imports: [CommonModule, PipeTrustedModule],
	declarations: [TooltipComponent, TooltipDirective],
	exports: [TooltipDirective, TooltipComponent],
})
export class TooltipModule {}
