import { AppColorDefinition, Sizes, StandardSizes, UITheme, UIThemes } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
	selector: 'ui-button',
	standalone: true,
	imports: [IconModule, SpinnerComponent, NgStyle],
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges {
	@Input() type: UITheme | 'link' | 'link-dark' = UIThemes.Primary;
	@Input() text: string = '';
	@Input() size: StandardSizes = Sizes.L;
	@Input() fullWidth: boolean = false;
	@Input() rightAligned: boolean = false;
	@Input() inverted: boolean = false;
	@Input() disabled: boolean = false;
	@Input() showSpinner: boolean = false;
	@Input() icon: string = '';
	@Input() color: AppColorDefinition | undefined;
	@Input() hideTextOnMobile: boolean = false;
	@Input() fullWidthMobile: boolean = false;

	@Output() buttonClicked = new EventEmitter<Event>();

	hovered: boolean = false;
	colorStyles = {
		backgroundColor: '',
		borderColor: '',
	};

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['color'] && this.color) {
			this.colorStyles = {
				backgroundColor: this.color.color,
				borderColor: this.color.color,
			};
		}
	}

	clicked(event: Event) {
		if (!this.disabled) {
			this.buttonClicked.emit(event);
		}
	}

	onHover(hover: boolean) {
		this.hovered = hover;
		if (this.color) {
			this.colorStyles = {
				backgroundColor: this.hovered ? this.color.buttonHover : this.color.color,
				borderColor: this.hovered ? this.color.buttonHover : this.color.color,
			};
		}
	}
}
