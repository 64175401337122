import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UITheme, UIThemes } from '@agilox/ui-common';

@Component({
	selector: 'ui-spinner',
	standalone: true,
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
	@Input() small: boolean = false;
	@Input() light: boolean = false;
	@Input() color: UITheme = UIThemes.Primary;
	@Input() inline: boolean = false;
}
