<!-- IMAGE -->
@if (slide.image) {
	<img
		class="block max-h-[150px] mx-auto mb-8"
		[ngStyle]="{ 'max-height': slide.image.maxHeight }"
		[src]="slide.image | getOnboardingImage"
		[alt]="'onboarding-' + (slide.headline | translate)"
	/>
}
<!-- HEADLINE -->
<ui-headline type="h2"> {{ slide.headline | translate }}</ui-headline>
<!-- TEXT -->
<p class="my-6" [innerHTML]="slide.text | translate"></p>
<!-- PRIMARY BUTTON -->
@if (slide.buttonText) {
	<ui-button
		type="primary"
		[text]="slide.buttonText | translate"
		[fullWidth]="true"
		(buttonClicked)="onButtonClicked(slide.buttonAction)"
		data-cy="c-onboarding-primary-button"
	>
	</ui-button>
}
<!-- LINK BUTTON-->
@if (slide.linkText) {
	<div class="mt-2">
		<ui-button
			type="link"
			[text]="slide.linkText | translate"
			[fullWidth]="true"
			(buttonClicked)="onButtonClicked(slide.linkAction)"
			data-cy="c-onboarding-link-button"
		></ui-button>
	</div>
}
