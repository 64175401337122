import { ReportPerformanceColor } from '@agilox/common';

export enum PerformanceColor {
	busy = ReportPerformanceColor.busy,
	charge = ReportPerformanceColor.charge,
	idle = ReportPerformanceColor.idle,
	wait = ReportPerformanceColor.wait,
	adv_protective = ReportPerformanceColor.protective,
	adv_obstruction = ReportPerformanceColor.obstruction,
	issue_t = ReportPerformanceColor.t_issue,
	issue_p = ReportPerformanceColor.p_issue,
	manual = ReportPerformanceColor.manual,
	offline = ReportPerformanceColor.offline,
}
