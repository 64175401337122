<div
	class="ui-select-option"
	*ngIf="option"
	[class.is-disabled]="option.isDisabled"
	[class.is-selected]="selected"
	[class.is-focused]="focused"
	(click)="emitSelectedOption()"
	[attr.data-cy]="'select-option'"
	[attr.data-cy-2]="'select-option-' + selected ? 'selected' : 'not-selected'"
>
	<ui-checkbox
		class="ui-select-option__checkbox"
		[ngClass]="{ 'ui-select-option__checkbox--icon': option.icon }"
		[checked]="selected"
		[disabled]="option.isDisabled ?? false"
		*ngIf="multiEnabled"
		(checkedChange)="emitSelectedOption()"
	>
	</ui-checkbox>

	<ng-container
		*ngIf="customOptionTemplate && customOptionTemplate?.templateRef; else defaultTemplate"
	>
		<ng-container
			*ngTemplateOutlet="customOptionTemplate.templateRef; context: { $implicit: option }"
		></ng-container>
	</ng-container>

	<ng-template #defaultTemplate>
		@if (option.icon) {
			<ui-icon class="-ml-2 mr-1" [icon]="option.icon"></ui-icon>
		}
		<span class="ui-select-option__title" [class.is-ellipsed]="!fullWidth">
			{{ option.title }}
		</span>

		<div class="ui-select-option__pill" *ngIf="option.pillText">
			<ui-pill
				[value]="option.pillText"
				[disabled]="option.isDisabled ?? false"
				type="secondaryOutline"
			></ui-pill>
		</div>
	</ng-template>
</div>
