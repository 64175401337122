export enum SelftestColors {
	PRIMARY = '#664CCE',
	PRIMARY900 = '#523DA5',
	PRIMARY800 = '#5C44B9',
	PRIMARY700 = '#664CCE',
	PRIMARY600 = '#8570D8',
	PRIMARY500 = '#A394E2',
	PRIMARY400 = '#BAAEE9',
	PRIMARY300 = '#D1C9F0',
	PRIMARY200 = '#E0DBF5',
	PRIMARY100 = '#F0EDFA',
}
