export const MachineModel = {
	ONE: 'ONE',
	ONEV2: 'ONEV2',
	NFK: 'NFK',
	NFKV2: 'NFKV2',
	APPLIANCE: 'APPLIANCE',
	BOXCARRIER: 'BOXCARRIER',
	IPCSIMPLE: 'IPCSIMPLE',
	OCF: 'OCF',
	OCFV2: 'OCFV2',
	ODM: 'ODM',
	ODMV2: 'ODMV2',
	OPS: 'OPS',
	OFL: 'OFL',
} as const;
