import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Headline, Headlines } from '@agilox/ui-common';
import { NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'ui-headline',
	standalone: true,
	imports: [NgSwitchCase, NgTemplateOutlet, NgSwitch],
	templateUrl: './headline.component.html',
	styleUrls: ['./headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineComponent {
	@Input() type: Headline = Headlines.H2;
	@Input() light: boolean = false;
	@Input() ellipsis: boolean = false;
}
