import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CertificateState } from '../enums/certificate-state.enum';

@Pipe({
	name: 'certificateTagText',
})
export class CertificateTagTextPipe implements PipeTransform {
	constructor(private translate: TranslateService) {}
	transform(state: CertificateState): string {
		if (state === CertificateState.VALID_UNLIMITED || state === CertificateState.VALID) {
			return '';
		}
		return this.translate.instant('certificates.' + state);
	}
}
