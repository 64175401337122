import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ui-select-button',
	templateUrl: './select-button.component.html',
	styleUrls: ['./select-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent {
	@Input() label: string = '';
	@Input() icon: string = '';
	@Input() disabled: boolean = false;

	@Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

	click(event: Event) {
		if (!this.disabled) {
			this.buttonClicked.emit(event);
		}
	}
}
