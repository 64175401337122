export enum App {
	my = 'my',
	analytics = 'analytics',
	academy = 'academy',
	docs = 'docs',
	service = 'service',
	selftest = 'selftest',
	lifecycle = 'lifecycle',
	plan = 'plan',
}
