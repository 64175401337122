<div class="ui-mobile-nav-list" *ngIf="isOpen">
	<ui-mobile-sub-nav-list-entry
		*ngFor="let item of submenuItems"
		[item]="item"
		[isSubmenuOpen]="currentlyOpenedSubmenu === item || childrenAreActive(item) || !!item.active"
		[currentUrl]="currentUrl"
		[navColor]="navColor"
		(clicked)="toggleEntry($event)"
	>
	</ui-mobile-sub-nav-list-entry>
</div>
