<div class="c-user-notification-item flex flex-col gap-3">
	@if (notification) {
		<div class="flex gap-1">
			<ui-tag
				[text]="(notification.created | date: 'shortDate' : 'UTC') ?? ''"
				[type]="notification.read ? 'secondaryLight' : 'dark'"
			></ui-tag>
			@if (!notification.read) {
				<ui-tag [text]="'notifications.new' | translate" type="dark"></ui-tag>
			}
		</div>
		<div class="flex gap-2 items-center">
			<ui-button
				type="link-dark"
				icon="x_arrow_up"
				(buttonClicked)="toggleExpand()"
				[ngClass]="isExpanded ? 'rotate-180 transition-all' : 'rotate-0'"
				size="m"
			></ui-button>

			<ui-headline type="h4" class="block -mb-4">
				{{ notification.title }}
			</ui-headline>
		</div>
		<div [innerHTML]="notification.text | trusted" [hidden]="!isExpanded"></div>
	}
</div>
