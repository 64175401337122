import { Inject, Injectable } from '@angular/core';
import { MenuPoint } from '@agilox/ui-common';
import { Role } from '../enums/role.enum';
import { MENU_POINTS } from '../tokens/menu-points.token';
import { NavVariant } from '../enums/nav-variant.enum';

@Injectable()
export class MenuPointService {
	constructor(@Inject(MENU_POINTS) private menuPoints: { [key: string]: MenuPoint[] }) {}

	public getMenuPointsByCategory(type: NavVariant, role?: Role): MenuPoint[] {
		return this.filterByRole(this.menuPoints[type.toLowerCase()], role) || [];
	}

	private filterByRole(menuPoints: MenuPoint[], role?: string): MenuPoint[] {
		return menuPoints
			?.filter(
				(menuPoint: MenuPoint) =>
					menuPoint.roles.length === 0 ||
					menuPoint.roles.some((menuPointRole: any) => menuPointRole === role)
			)
			.map((menuPoint: MenuPoint) => {
				if (menuPoint.submenus && menuPoint.submenus?.length > 0) {
					menuPoint.submenus = this.filterByRole(menuPoint.submenus, role);
				}
				return menuPoint;
			});
	}
}
