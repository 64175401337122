<div
	class="ui-mobile-sub-nav-list-entry"
	*ngIf="item"
	(click)="clicked.emit(item)"
	[attr.data-id]="'sub-nav-entry-' + item.name"
>
	<div
		class="ui-mobile-sub-nav-list-entry__item"
		[class.is-active]="currentUrl.includes(item.url) && !item.callback"
	>
		<ui-icon
			class="ui-mobile-sub-nav-list-entry__icon"
			[icon]="item.icon"
			size="l"
			[type]="navColor"
		></ui-icon>
		<div
			class="ui-mobile-sub-nav-list-entry__text ui-mobile-sub-nav-list-entry__text--{{ navColor }}"
		>
			{{ item.name | translate }}
		</div>
	</div>

	<div
		class="ui-mobile-sub-nav-list-entry__subMenu"
		[class.is-open]="isSubmenuOpen"
		*ngIf="item.submenus"
	>
		<div
			class="ui-mobile-sub-nav-list-entry__subMenuItem"
			[class.is-open]="isSubmenuOpen"
			*ngFor="let subMenuItem of item.submenus"
			(click)="clicked.emit(subMenuItem)"
			[class.is-active]="(currentUrl.includes(subMenuItem.url) && !subMenuItem.callback) || subMenuItem.active"
		>
			<ui-divider
				type="vertical"
				[noMargin]="true"
				[color]="navColor === 'light' ? 'white' : 'darkGrey'"
				class="ui-mobile-sub-nav-list-entry__divider"
			></ui-divider>
			<div class="ui-mobile-sub-nav-list-entry__textWrapper">
				@if (subMenuItem.icon) {
					<ui-icon [type]="navColor" [icon]="subMenuItem.icon" size="m"> </ui-icon>
				}
				<div
					class="ui-mobile-sub-nav-list-entry__text ui-mobile-sub-nav-list-entry__text--{{
						navColor
					}}"
				>
					{{ subMenuItem.name | translate }}
				</div>
			</div>
		</div>
	</div>
</div>
