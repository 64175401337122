import { environment } from '@academy/env/environment';
import {
	AGILOX_API_ENDPOINT,
	AppNavMenuPointsService,
	ChunkLoadingFailedErrorHandler,
	ENVIRONMENT,
	GOOGLE_TAG_MANAGER_ID,
	GoogleAnalyticsService,
	localeProvider,
	MENU_POINTS,
	MenuPointService,
	ROLE,
	TranslationsLoader,
} from '@agilox/common';
import { HelpContentComponent, UserNotificationsService } from '@agilox/ui';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
	BrowserAnimationsModule,
	NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './general/interceptors/auth.interceptor';
import { ErrorInterceptor } from './general/interceptors/error.interceptor';
import { AuthenticationService } from './general/services/authentication.service';
import { UserSettingsService } from './general/services/user-settings.service';
import { SharedModule } from './general/shared.module';
import { desktopMenuPoints } from './general/constants/desktop-menupoints.const';
import { mobileMenupoints } from './general/constants/mobile-menupoints.const';

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		AppRoutingModule,
		BrowserModule,
		SharedModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationsLoader,
				deps: [HttpClient],
			},
		}),
		HelpContentComponent,
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		// insert errorhandlers after sentry, otherwise sentry interupts to early in error-handling
		{
			provide: ErrorHandler,
			useClass: ChunkLoadingFailedErrorHandler,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (authenticationService: AuthenticationService) => () =>
				authenticationService.getUser(),
			deps: [AuthenticationService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		AppNavMenuPointsService,
		{
			provide: MENU_POINTS,
			useValue: {
				desktop: desktopMenuPoints,
				mobile: mobileMenupoints,
			},
		},
		MenuPointService,
		{
			provide: ROLE,
			useFactory: (userService: UserSettingsService) => userService.onRoleChange$,
			deps: [UserSettingsService],
		},
		{
			provide: ENVIRONMENT,
			useValue: environment,
		},
		{
			provide: GOOGLE_TAG_MANAGER_ID,
			useValue: 'G-DC0EZYHRN9',
		},
		GoogleAnalyticsService,
		{
			provide: AGILOX_API_ENDPOINT,
			useValue: environment.agiloxApi,
		},
		{
			provide: UserNotificationsService,
			deps: [AGILOX_API_ENDPOINT],
		},
		localeProvider,
	],
})
export class AppModule {}
