<div class="ui-radio">
	<div class="ui-radio__container" *ngFor="let radio of radioButtons">
		<label
			class="ui-radio__radio"
			[class.is-disabled]="radio.disabled || disabled"
			(change)="onChange(radio.value)"
		>
			<span class="ui-radio__label" [class.is-disabled]="radio.disabled || disabled">{{
				radio.label
			}}</span>
			<input
				type="radio"
				class="ui-radio__input"
				[name]="groupName"
				[value]="radio.value"
				[disabled]="radio.disabled || disabled"
				[checked]="formControl.value === radio.value"
				(change)="handleChange(radio.value)"
			/>
			<span class="ui-radio__checkmark" [class.is-disabled]="radio.disabled || disabled"></span>
		</label>
	</div>
</div>
