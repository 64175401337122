import { AppColorDefinition, BoxContentItem, UITheme, UIThemes } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { IconModule } from '../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { HeadlineComponent } from '../headline/headline.component';
import { TagComponent } from '../tag/tag.component';
import { PillComponent } from '../pill/pill.component';

@Component({
	selector: 'ui-box-content',
	standalone: true,
	imports: [
		TagComponent,
		HeadlineComponent,
		IconModule,
		TranslateModule,
		ButtonComponent,
		PillComponent,
	],
	templateUrl: './box-content.component.html',
	styleUrls: ['./box-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxContentComponent {
	@Input() title: string | undefined;
	@Input() showDownloadButton: boolean = false;
	@Input() buttonText: string = '';
	@Input() buttonType: UITheme = UIThemes.Secondary;
	@Input() disabled: boolean = false;
	@Input() contentBoxItems: BoxContentItem[] = [];
	@Input() link: string | undefined;
	@Input() color: AppColorDefinition | undefined;
	@Input() tagText: string | undefined;

	@Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() downloadClicked: EventEmitter<void> = new EventEmitter<void>();

	private router: Router = inject(Router);

	onButtonClicked() {
		if (this.disabled) {
			return;
		}
		if (this.link) {
			this.router.navigateByUrl(this.link);
		} else {
			this.buttonClicked.emit();
		}
	}
	onDownloadClicked() {
		this.downloadClicked.emit();
	}
}
