import { Pipe, PipeTransform } from '@angular/core';

/**
 * transforms a falsifyable value into a "-"
 * (used in tables)
 */
@Pipe({
	name: 'empty',
})
export class EmptyPipe implements PipeTransform {
	/**
	 * checks if the input is null, undefined, false or empty string or array
	 * @param value
	 * @param args
	 */
	transform(value: unknown, ...args: unknown[]): unknown {
		if ((!value && value !== 0) || (Array.isArray(value) && value.length === 0)) {
			return '-';
		}
		return value;
	}
}
