import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { HeadlineComponent } from '../headline/headline.component';
import { overlayAnimations } from './overlay.animations';

@Component({
	selector: 'ui-overlay',
	templateUrl: 'overlay.component.html',
	styleUrls: ['overlay.component.scss'],
	standalone: true,
	imports: [NgIf, IconModule, HeadlineComponent, NgClass],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: overlayAnimations,
})
export class OverlayComponent {
	@Input() background: boolean = true;
	@Input() showCloseButton: boolean = true;
	@Input() dark: boolean = false;
	@Input() header: string = '';
	@Input() closeOnBackgroundClick: boolean = false;
	@Input() styleClass: string = '';

	@Output() closeOverlay: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * emits the close event
	 */
	onClose() {
		this.closeOverlay.emit();
	}

	onBackdropClick() {
		if (this.closeOnBackgroundClick) {
			this.onClose();
		}
	}

	onInnerClick(event: Event) {
		/**
		 * Only stop the event if it isn't a link
		 */
		if (!(event.target instanceof HTMLAnchorElement)) {
			event.stopPropagation();
			event.preventDefault();
			event.stopImmediatePropagation();
		}
	}
}
