import { NgModule } from '@angular/core';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { CommonModule } from '@angular/common';
import {
	DropdownDirective,
	DropdownContentDirective,
	DropdownTriggerDirective,
} from './directives';

@NgModule({
	declarations: [
		DropdownComponent,
		DropdownDirective,
		DropdownTriggerDirective,
		DropdownContentDirective,
	],
	exports: [
		DropdownComponent,
		DropdownTriggerDirective,
		DropdownDirective,
		DropdownContentDirective,
	],
	imports: [CommonModule],
})
export class DropdownModule {}
