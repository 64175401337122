import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../public-api';
import { AppError, ErrorType } from '@agilox/common';

@Component({
	selector: 'ui-error-content',
	templateUrl: './error-content.component.html',
	styleUrls: ['./error-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, TranslateModule],
})
export class ErrorContentComponent {
	@Input() error!: AppError;
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	protected readonly ErrorType = ErrorType;

	reload() {
		window.location.reload();
	}

	contactSupport() {
		const subject = `Error at application: ${this.error.application?.toUpperCase() ?? ''}`;
		const body = `URL: ${this.error.url} \n Error: ${this.error.message} \n User: ${this.error.user?.email ?? ''}`;
		window.location.href = `mailto:support@agilox.net?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
	}

	public close() {
		this.closed.emit();
	}
}
