import { Vehicle } from '@agilox/common';
import { SelectOption, VehicleSelectOptionGroup } from '@agilox/ui-common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'vehicleSelect',
	standalone: true,
})
export class VehicleSelectPipe implements PipeTransform {
	transform(vehicles: Vehicle[] | undefined | null): VehicleSelectOptionGroup[] {
		if (!vehicles) {
			return [];
		}
		const options = vehicles?.map((vehicle: Vehicle) => ({
			value: vehicle,
			title: vehicle.name + ' (' + vehicle.serial + ') | ',
		}));

		return this.groupByUnion(options);
	}

	private groupByUnion(vehicles: SelectOption<Vehicle>[]): VehicleSelectOptionGroup[] {
		const groupedVehicles = new Map<string, SelectOption<Vehicle>[]>();
		vehicles.forEach((vehicle) => {
			if (groupedVehicles.has(vehicle.value?.unionUuid || '')) {
				groupedVehicles.get(vehicle.value?.unionUuid || '')?.push(vehicle);
			} else {
				groupedVehicles.set(vehicle.value?.unionUuid || '', [vehicle]);
			}
		});
		return Array.from(groupedVehicles.entries()).map(([name, options]) => ({
			name: options[0].value?.union || '',
			uuid: name,
			options,
		}));
	}
}
