<div
	class="ui-mobile-filter-toggle hide-desktop"
	(click)="toggleMobileFilter()"
	[class.is-open]="open"
>
	<div class="ui-mobile-filter-toggle__icon">
		<ui-icon [icon]="icon ? icon : 'cal_edit'" size="m"></ui-icon>
	</div>

	{{ (open ? this.textOpened : this.textClosed) | translate }}
</div>
