import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrustedPipe } from './pipe-trusted.pipe';

@NgModule({
	declarations: [TrustedPipe],
	exports: [TrustedPipe],
	imports: [CommonModule],
})
export class PipeTrustedModule {}
