import { ToolbarItem } from '../interfaces/toolbar-item.interface';
import agiloxIcons from '../../icon/library/svg-icons';

export const ToolBarItems: ToolbarItem[] = [
	{ class: 'header', value: '2', icon: agiloxIcons.h_2 },
	{ class: 'bold', icon: agiloxIcons.bold },
	{ class: 'list', value: 'bullet', icon: agiloxIcons.view_list },
	{ class: 'list', value: 'ordered', icon: agiloxIcons.view_list_number },
	{ class: 'link', icon: agiloxIcons.link_on },
	{ class: 'image', icon: agiloxIcons.media },
];
