import { OnboardingAction } from '../enums/onboarding-action.enum';

export interface OnboardingInterface {
	image?: {
		en: string;
		de: string;
		maxHeight?: string;
	};
	headline: string;
	text: string;
	buttonText: string;
	buttonAction: OnboardingAction;
	linkText: string;
	linkAction: OnboardingAction;
}
