import { User } from '@agilox/common';
import { ErrorType } from '../enums/error-type.enum';

export interface AppError {
	application?: string;
	user?: User;
	message?: string;
	url?: string;
	type: ErrorType;
}
