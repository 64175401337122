import { MenuPoint } from '@agilox/ui-common';
import { academyMenuPoints } from './menupoint.const';

export const desktopMenuPoints: Array<MenuPoint> = [
	academyMenuPoints.dashboard,
	academyMenuPoints.trainings,
	academyMenuPoints.certificates,
	academyMenuPoints.media,
	academyMenuPoints.archive,
	academyMenuPoints.help,
	academyMenuPoints.logout,
];
