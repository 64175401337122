<div class="ui-datepicker" [class.ui-datepicker--inline]="type === Datepickers.INLINE">
	@if (type === Datepickers.INLINE) {
		<div class="ui-datepicker__inline">
			<div class="ui-datepicker__base">
				<ui-datepicker-base
					[currentlyDisplayedMonth]="currentlyDisplayedMonth"
					[disabled]="formControl.disabled"
					[selectedDate]="formControl.value"
					(navDateChange)="onNavigationChange($event)"
					(calendarWeekClicked)="calendarWeekClicked.emit($event)"
					(dateSelectionChange)="onDateSelectionChange($event)"
					[minDate]="min"
					[maxDate]="max"
					[syncedDate]="syncedDate"
				></ui-datepicker-base>
			</div>
		</div>
	} @else {
		<ui-datepicker-input
			[placeholder]="placeholder"
			[formControl]="formControl"
			[minDate]="min"
			[maxDate]="max"
			[showWhenValid]="showWhenValid"
			[syncedDate]="syncedDate"
			[currentlyDisplayedMonth]="currentlyDisplayedMonth"
			(calendarWeekClicked)="calendarWeekClicked.emit($event)"
			[inputId]="inputId"
			(calendarOpened)="onCalendarOpened()"
		>
		</ui-datepicker-input>
	}
</div>
