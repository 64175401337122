import { AppMenuPoint, MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	inject,
	Input,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuPointAction } from '@agilox/common';

@Component({
	selector: 'ui-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
	/** logo path */
	@Input() logoPath: string | undefined;

	/** menu item */
	@Input() items: MenuPoint[] | undefined;

	/** nav color */
	@Input() navColor: NavColor = NavColors.Dark;

	/** open */
	@Input() open: boolean = false;

	@Input() apps: Array<AppMenuPoint> = [];

	@Input() activeApp: AppMenuPoint | undefined;

	@Input() appNavVisible: boolean = true;

	/** Needed to handle the routing of the app bento nav */
	@Input() environment: string = 'live';

	@Input() beta: boolean = false;

	@Output() callbackEvent: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();

	/** Needed to be able to be used as a web component */
	@Output() itemToRouteClicked: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();

	openedMenuPoint: MenuPoint | undefined;

	/** true if sub menu was opened by click
	 * used to decide if the submenu should be closed on mouseleave
	 * */
	subMenuOpenedByClick: boolean = false;

	private router: Router = inject(Router);

	@HostListener('window: click', ['$event'])
	@HostListener('window:keydown.escape', ['$event'])
	clickOutside(event?: MouseEvent | KeyboardEvent) {
		const target = event?.target as HTMLElement;
		const hasUiNavClass = target?.closest('.ui-nav');
		if (!hasUiNavClass) {
			this.closeSubMenu();
		}
	}

	/**
	 * handles menu point action
	 * @param event
	 * If the menu point has submenus, the submenu is opened
	 * If the menu point has no submenus, the url is navigated to
	 * If the menu point has callbackEvent = true, the callbackEvent event is emitted
	 */
	onMenuPointAction(event: MenuPointAction) {
		if (event.menuPoint.callback) {
			this.callbackEvent.emit(event.menuPoint);
			return;
		}
		this.subMenuOpenedByClick = event.click;
		if (event.menuPoint.submenus) {
			this.openSubMenu(event.menuPoint);
		} else {
			this.itemToRouteClicked.emit(event.menuPoint);
			this.router.navigateByUrl(event.menuPoint.url ?? '').catch((error) => {});
			this.closeSubMenu();
		}
	}

	/**
	 * handles mouse enter
	 * If the submenu was opened by clicking, the submenu is not closed on mouse leave
	 * else the submenu is closed on mouse leave
	 */
	onMouseLeave() {
		if (!this.subMenuOpenedByClick) {
			this.closeSubMenu();
		}
	}

	openSubMenu(menuPoint: MenuPoint) {
		this.openedMenuPoint = menuPoint;
		this.open = true;
	}

	closeSubMenu() {
		this.openedMenuPoint = undefined;
		this.open = false;
	}
}
