import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

export const offcanvasAnimations: AnimationMetadata[] = [
	trigger('slideInOut', [
		state(
			'closed',
			style({
				transform: 'translateX(100%)',
			})
		),
		state(
			'open',
			style({
				transform: 'translateX(0)',
			})
		),
		transition('closed => open', [animate('200ms')]),
		transition('open => closed', [animate('200ms')]),
	]),
];
