import {
	animate,
	AnimationTriggerMetadata,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';

export const overlayAnimations: AnimationTriggerMetadata[] = [
	trigger('popupAnimation', [
		state(
			'void',
			style({
				opacity: 0,
				transform: 'scale(0.85)',
			})
		),
		transition(':enter', [
			style({
				opacity: 0,
				transform: 'scale(0.85)',
			}),
			animate(
				'200ms ease-out',
				style({
					opacity: 1,
					transform: 'scale(1)',
				})
			),
		]),
		transition(':leave', [
			style({
				opacity: 1,
				transform: 'scale(1)',
			}),
			animate(
				'200ms ease-in',
				style({
					opacity: 0,
					transform: 'scale(0.85)',
				})
			),
		]),
	]),
];
