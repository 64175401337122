export function convertToCSV(
	data: any[],
	headers: string[],
	propertyNames: string[],
	emptyPlaceholder?: string
) {
	if (!data.length) {
		return null;
	}
	const csvRows = [];
	csvRows.push(headers.join(';'));
	for (const row of data) {
		const values = propertyNames.map((propertyName) => {
			let val = row[propertyName];
			if (!val && emptyPlaceholder) {
				val = emptyPlaceholder;
			}

			const escaped = ('' + val).replace(/"/g, '\\"');
			return `"${escaped}"`;
		});
		csvRows.push(values.join(';'));
	}
	return csvRows.join('\n');
}
