<p>
	{{ error.type.toLowerCase() + '.solution' | translate }}
</p>
<div class="pt-6 flex-col">
	<ui-button
		type="primary"
		class="flex-1"
		[fullWidth]="true"
		(buttonClicked)="reload()"
		[text]="
			(error.type === ErrorType.NO_INTERNET_ERROR
				? 'network_error.reload_page'
				: 'general.try_again'
			) | translate
		"
	>
	</ui-button>

	@switch (error.type) {
		@case (ErrorType.NETWORK_ERROR) {
			<ui-button
				type="link"
				[fullWidth]="true"
				(buttonClicked)="contactSupport()"
				[text]="'network_error.report_issue' | translate"
				class="flex-1"
				data-cy="network-error-button"
			>
			</ui-button>
		}
		@case (ErrorType.CHUNK_ERROR) {
			<ui-button
				[fullWidth]="true"
				[text]="'chunk_error.no' | translate"
				(buttonClicked)="close()"
				type="link"
				class="flex-1"
				data-cy="chunk-error-button"
			></ui-button>
		}
		@case (ErrorType.NO_INTERNET_ERROR) {
			<ui-button
				[fullWidth]="true"
				[text]="'general.cancel' | translate"
				(buttonClicked)="close()"
				type="link"
				class="flex-1"
				data-cy="chunk-error-button"
			></ui-button>
		}
	}
</div>
