import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';

@Pipe({
	name: 'yearSelect',
})
export class YearSelectPipe implements PipeTransform {
	transform(value: SelectOption<number>[], ...args: unknown[]): SelectOption<number>[] {
		const minDate: Date = args[0] as Date;
		const maxDate: Date = args[1] as Date;
		return this.disableOptions(
			value.map((val: SelectOption<number>) => {
				return {
					...val,
					isDisabled: false,
				};
			}),
			minDate,
			maxDate
		);
	}

	/**
	 * Disable options based on minDate and maxDate
	 * A year is disabled if:
	 * - the year is smaller than the minDate year
	 * - the year is greater than the maxDate year
	 * @param values
	 * @param minDate
	 * @param maxDate
	 * @private
	 */
	private disableOptions(
		values: SelectOption<number>[],
		minDate: Date,
		maxDate: Date
	): SelectOption<number>[] {
		return values.map((option) => {
			if (minDate) {
				if (option.value! < minDate.getFullYear()) {
					option.isDisabled = true;
				}
			}
			if (maxDate) {
				if (option.value! > maxDate.getFullYear()) {
					option.isDisabled = true;
				}
			}
			return option;
		});
	}
}
