import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Language } from '@agilox/common';
import { UserNotification } from '../models/user-notification';

@Component({
	selector: 'ui-user-notification-item',
	templateUrl: './user-notification-item.component.html',
	styleUrls: ['./user-notification-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UserNotificationItemComponent {
	@Input() notification: UserNotification | undefined;
	@Input() isExpanded: boolean = true;

	toggleExpand(): void {
		this.isExpanded = !this.isExpanded;
	}
}
