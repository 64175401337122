import { Vehicle } from '@agilox/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'vehicleOptionTooltip',
	standalone: true,
})
export class VehicleOptionTooltipPipe implements PipeTransform {
	transform(vehicle: Vehicle | undefined | null): string {
		if (!vehicle) {
			return '';
		}
		return (
			(vehicle.name ?? '') +
			'(' +
			vehicle.serial +
			') | ' +
			(vehicle.union ?? '-') +
			' | ' +
			vehicle.customer
		);
	}
}
