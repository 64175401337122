import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ui-toolbar',
	standalone: true,
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {}
