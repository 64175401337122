import { inject, Pipe, PipeTransform } from '@angular/core';
import { SelectOption, VehicleSelectOptionGroup } from '@agilox/ui-common';
import { getSubscriptionRemaining, PluralTranslatePipe, Role, Vehicle } from '@agilox/common';

@Pipe({
	name: 'vehicleSelectOption',
})
export class VehicleSelectOptionPipe implements PipeTransform {
	pluralPipe: PluralTranslatePipe = inject(PluralTranslatePipe);
	transform(
		vehicles: Vehicle[] | null,
		currentlySelectedUnion: string | undefined | null,
		role: string
	): VehicleSelectOptionGroup[] {
		if (!vehicles) {
			return [];
		}

		const options = vehicles.map((vehicle) => {
			const subscriptionRemaining: number = getSubscriptionRemaining(vehicle.subscription_end);
			return {
				value: vehicle,
				title: `${vehicle.name} (${vehicle.serial}) |`,
				isDisabled:
					(currentlySelectedUnion && vehicle.unionUuid !== currentlySelectedUnion) ||
					(subscriptionRemaining <= 0 && role === Role.partner),
				pillDisabled: subscriptionRemaining <= 0,
			};
		});
		const grouped: VehicleSelectOptionGroup[] = this.groupByUnion(options);
		return this.sortGroup(grouped, currentlySelectedUnion || '');
	}

	/**
	 * The selected group should be the first one in the list
	 * @param groups
	 * @param union
	 * @private
	 */
	private sortGroup(groups: VehicleSelectOptionGroup[], union: string): VehicleSelectOptionGroup[] {
		const selectedGroup = groups.find((group) => group.uuid === union);
		if (selectedGroup) {
			/**
			 * The selected group should be the first one
			 */
			groups.splice(groups.indexOf(selectedGroup), 1);
			groups.unshift(selectedGroup);
		}
		return groups;
	}

	private groupByUnion(vehicles: SelectOption<Vehicle>[]): VehicleSelectOptionGroup[] {
		const groupedVehicles = new Map<string, SelectOption<Vehicle>[]>();
		vehicles.forEach((vehicle) => {
			if (groupedVehicles.has(vehicle.value?.unionUuid || '')) {
				groupedVehicles.get(vehicle.value?.unionUuid || '')?.push(vehicle);
			} else {
				groupedVehicles.set(vehicle.value?.unionUuid || '', [vehicle]);
			}
		});
		return Array.from(groupedVehicles.entries()).map(([name, options]) => ({
			name: options[0].value?.union || '',
			uuid: name,
			options,
		}));
	}
}
