import { NgModule } from '@angular/core';
import { DatepickerBaseComponent } from './datepicker-base.component';
import { DatepickerCalendarComponent } from './datepicker-calendar/datepicker-calendar.component';
import { DatepickerNavigationComponent } from './datepicker-navigation/datepicker-navigation.component';
import { MonthSelectPipe } from './datepicker-navigation/pipes/month-select.pipe';
import { YearSelectPipe } from './datepicker-navigation/pipes/year-select.pipe';
import { CommonModule } from '@angular/common';
import { IconModule } from '../../icon/icon.module';
import { InputModule } from '../../input/input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from '../../select/select.module';
import { TooltipModule } from '@agilox/ui';

@NgModule({
	declarations: [
		DatepickerBaseComponent,
		DatepickerCalendarComponent,
		DatepickerNavigationComponent,
		MonthSelectPipe,
		YearSelectPipe,
	],
	imports: [
		CommonModule,
		IconModule,
		InputModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		SelectModule,
		TooltipModule,
	],
	exports: [DatepickerBaseComponent],
})
export class DatepickerBaseModule {}
