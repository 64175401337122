import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'ui-checkbox',
	standalone: true,
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() label: string = '';
	@Input() icon: 'checkmark' | 'minus' = 'checkmark';
	@Input() checked: boolean = false;
	@Input() disabled: boolean = false;

	@Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	private onChange = (value: boolean) => {};
	private onTouched = () => {};

	writeValue(value: any): void {
		this.checked = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	toggleCheckbox() {
		if (!this.disabled) {
			this.checked = !this.checked;
			this.onChange(this.checked);
			this.onTouched();
			this.checkedChange.emit(this.checked);
		}
	}
}
