import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../../../button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'ui-help-content',
	templateUrl: './help-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, TranslateModule],
})
export class HelpContentComponent {
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	public onEmailClick(): void {
		window.location.href = 'mailto:support@agilox.net';
	}

	public onCancelClick(): void {
		this.closed.emit();
	}
}
