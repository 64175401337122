import { Position } from '../types/position.type';

/**
 * represents a Menupoint with the given parameter
 * @param name name of menupoint
 * @param url the url it should navigator (can also be a json object)
 * @param icon url to the icon
 * @param roles roles which are allowed
 * @param submenus submenu points
 * @param isOpen if menupoint is open
 * @param position
 * @param callback if menupoint has a callback
 * @param active if menupoint is active (for styling, only needed when not possible to use routerLinkActive)
 * @param description description for tooltip
 * @param tooltipPosition position of tooltip
 */
export interface MenuPoint {
	name: string;
	url: string;
	icon?: string;
	roles: Array<string>;
	submenus?: Array<MenuPoint>;
	position?: 'top' | 'bottom';
	callback?: boolean;
	active?: boolean;
	description?: string;
	tooltipPosition?: Position;
}
