import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { IconModule } from '../icon/icon.module';
import { RichTextEditorToolbarComponent } from './rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [RichTextEditorComponent, RichTextEditorToolbarComponent],
	imports: [QuillModule.forRoot(), IconModule, NgClass, ReactiveFormsModule, FormsModule],
	exports: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
