<div class="ui-checkbox">
	<label class="ui-checkbox__checkbox" [class.is-disabled]="disabled">
		@if (label) {
			<div class="ui-checkbox__label" [class.is-disabled]="disabled">
				{{ label }}
			</div>
		}

		<input
			class="ui-checkbox__input"
			type="checkbox"
			[disabled]="disabled"
			[checked]="checked"
			(change)="toggleCheckbox()"
		/>
		<span
			class="ui-checkbox__checkmark ui-checkbox__checkmark--{{ icon }}"
			[class.is-disabled]="disabled"
		></span>
	</label>
</div>
