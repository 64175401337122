import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	Renderer2,
} from '@angular/core';
import { Position, Positions } from '@agilox/ui-common';

/**
 * Directive that checks if an element is overflowing the page and moves it accordingly
 * Will only work if the element is positioned absolute
 */
@Directive({
	selector: '[directivePageOverflow]',
})
export class PageOverflowDirective implements AfterViewInit, OnChanges {
	/**
	 * direction of the overflow that should be checked
	 */
	@Input() overflowDirection: Position = Positions.Bottom;

	/**
	 * element that should be moved
	 * if not set, the directive will move the element itself
	 * **/
	@Input() parentElement: HTMLDivElement | undefined;

	/**
	 * value that should be checked for changes
	 * if not set, the directive will only check for window resize,
	 * should be used if the element is dynamic
	 * **/
	@Input() changes: any;

	constructor(
		private _element: ElementRef,
		private renderer: Renderer2
	) {}

	ngAfterViewInit() {
		this.checkOverflow();
	}

	checkOverflow() {
		switch (this.overflowDirection) {
			case Positions.Bottom:
				this.checkBottomOverflow();
				break;
			case Positions.Top:
				this.checkTopOverflow();
				break;
			case Positions.Left:
				this.checkLeftOverflow();
				break;
			case Positions.Right:
				this.checkRightOverflow();
				break;
			default:
				break;
		}
	}

	checkBottomOverflow() {
		const bottomOfElement = this._element.nativeElement.getBoundingClientRect().bottom;
		const bottomOfWindow = window.innerHeight;
		if (bottomOfElement > bottomOfWindow) {
			/** move the element up by the difference + 2rem **/
			this.renderer.setStyle(
				this.parentElement ?? this._element.nativeElement,
				'transform',
				'translateY(-' + (bottomOfElement - bottomOfWindow + 32) + 'px)'
			);
		}
	}

	checkTopOverflow() {
		const topOfElement = this._element.nativeElement.getBoundingClientRect().top;
		const topOfWindow = 0;
		if (topOfElement < topOfWindow) {
			/** move the element down by the difference + 2rem **/
			this.renderer.setStyle(
				this.parentElement ?? this._element.nativeElement,
				'transform',
				'translateY(' + (topOfWindow - topOfElement + 32) + 'px)'
			);
		}
	}

	checkLeftOverflow() {
		const leftOfElement = this._element.nativeElement.getBoundingClientRect().left;
		const leftOfWindow = 0;
		if (leftOfElement < leftOfWindow) {
			/** move the element right by the difference + 2rem **/
			this.renderer.setStyle(
				this.parentElement ?? this._element.nativeElement,
				'transform',
				'translateX(' + (leftOfWindow - leftOfElement + 32) + 'px)'
			);
		}
	}

	checkRightOverflow() {
		const rightOfElement = this._element.nativeElement.getBoundingClientRect().right;
		const rightOfWindow = window.innerWidth;
		if (rightOfElement > rightOfWindow) {
			/** move the element left by the difference + 2rem **/
			this.renderer.setStyle(
				this.parentElement ?? this._element.nativeElement,
				'transform',
				'translateX(-' + (rightOfElement - rightOfWindow + 32) + 'px)'
			);
		}
	}

	reset() {
		this.renderer.setStyle(
			this.parentElement ?? this._element.nativeElement,
			'transform',
			'translate(0, 0)'
		);
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.checkOverflow();
	}

	ngOnChanges() {
		this.reset();
		this.checkOverflow();
	}
}
