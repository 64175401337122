import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPipe } from './pipe-empty';

@NgModule({
	declarations: [EmptyPipe],
	exports: [EmptyPipe],
	imports: [CommonModule],
})
export class PipeEmptyModule {}
