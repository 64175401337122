import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
	constructor(private readonly translate: TranslateService) {}

	getPaginatorIntl(): MatPaginatorIntl {
		const paginatorIntl = new MatPaginatorIntl();
		paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
		paginatorIntl.nextPageLabel = this.translate.instant('pagination.next_page');
		paginatorIntl.previousPageLabel = this.translate.instant('pagination.previous_page');
		paginatorIntl.firstPageLabel = this.translate.instant('pagination.first_page');
		paginatorIntl.lastPageLabel = this.translate.instant('pagination.last_page');

		return paginatorIntl;
	}

	private getRangeLabel(page: number, pageSize: number, length: number): string {
		if (length === 0 || pageSize === 0) {
			return this.translate.instant('pagination.from_zero', { length });
		}
		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex = Math.min(startIndex + pageSize, length);
		return this.translate.instant('pagination.from', {
			startIndex: startIndex + 1,
			endIndex,
			length,
		});
	}
}
