import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UserNotificationsService } from '../user-notifications.service';
import { map, Observable } from 'rxjs';
import { UserNotificationsState } from '../models/userNotificationsState';
import { UserNotification } from '../models/user-notification';

@Component({
	selector: 'ui-user-notifications-button',
	templateUrl: './user-notifications-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationsButtonComponent {
	private service: UserNotificationsService = inject(UserNotificationsService);

	notificationsCount$: Observable<number> = this.service.state$.pipe(
		map(
			(state: UserNotificationsState) =>
				state.notifications.filter((notification: UserNotification) => !notification.read).length
		)
	);

	toggleNotifications() {
		this.service.toggleNotificationsOffcanvas();
	}
}
