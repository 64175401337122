import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'ui-mobile-filter-toggle',
	standalone: true,
	imports: [IconModule, TranslateModule],
	templateUrl: './mobile-filter-toggle.component.html',
	styleUrls: ['./mobile-filter-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFilterToggleComponent {
	@Input() open: boolean = false;

	/** text to be displayed when menu is opened */
	@Input() textOpened: string = 'general.filter_hide';

	/** icon to be displayed*/
	@Input() icon: string = '';

	/** text to be displayed when menu is closed */
	@Input() textClosed: string = 'general.filter_show';

	/** emit if mobile filter is open or closed
	 * emits true if opened
	 * emits false if closed
	 * */
	@Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	/** toggle open and emit event */
	toggleMobileFilter() {
		this.open = !this.open;
		this.openChange.emit(this.open);
	}
}
