<div class="ui-tabs" [attr.data-cy]="'ui-tabs'">
	@for (tab of tabs; track tab; let i = $index) {
		<div
			class="ui-tabs__tab"
			[attr.data-cy]="'ui-tabs-' + tabs[i].key"
			[ngClass]="{
				'ui-tabs__tab--selected': i === selectedIndex,
				'ui-tabs__tab--disabled': tab.disabled,
			}"
			(click)="tabChanged(i)"
		>
			{{ tab.translation | translate }}
		</div>
	}
</div>
