export interface Vehicle {
	uuid: string;
	ats: boolean;
	azure: boolean;
	backup: boolean;
	city: string;
	collection: string | null;
	country: string;
	country_code: string;
	customer: string;
	maas: boolean;
	model: string;
	name: string;
	serial: string;
	subscription_end: number;
	street: string;
	type: string;
	union: string;
	zip: string;
	default_tenant: boolean;
	timezone: string;
	tracked: boolean;
	unionUuid: string;
	active: boolean;
}
