import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';

@Directive({
	selector: '[uiDropdownTrigger]',
})
export class DropdownTriggerDirective {
	@Input() uiDropdownDisabled: boolean = false;

	@Input() dropdownControl: FormControl | null = null;

	/** When the opening should only be triggered programmatically **/
	@Input() ignoreTrigger: boolean = false;

	constructor(public elementRef: ElementRef) {}

	/**
	 * Only trigger the dropdown if the uiDropdownTrigger input is true
	 * If not, the dropdown can only be closed
	 */
	@HostListener('click')
	onClick() {
		if (!this.uiDropdownDisabled && !this.ignoreTrigger) {
			if (this.dropdownControl) {
				this.dropdownControl.setValue(!this.dropdownControl.value);
			}
		}
	}
}
