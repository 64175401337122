<p>{{ 'help_overlay.body' | translate }}</p>
<div class="pt-6 flex flex-col gap-2">
	<ui-button
		[fullWidth]="true"
		[text]="'help_overlay.email' | translate"
		(buttonClicked)="onEmailClick()"
		data-cy="ui-help-content-email-button"
	></ui-button>
	<ui-button
		[fullWidth]="true"
		[text]="'general.cancel' | translate"
		type="link"
		(buttonClicked)="onCancelClick()"
		data-cy="ui-help-content-email-cancel-button"
	></ui-button>
</div>
