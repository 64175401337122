import { convertToUTCDate, dateToString } from '@agilox/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import { DatepickerDate } from '../../models/datepicker-date.interface';
import { CalendarWeek } from '../../models/calendar-week.interface';
import { CalendarWeekOutput } from '@agilox/ui-common';

@Component({
	selector: 'ui-datepicker-calendar',
	templateUrl: './datepicker-calendar.component.html',
	styleUrls: ['./datepicker-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCalendarComponent {
	@Input() minDate: Date | undefined = undefined;
	@Input() maxDate: Date | undefined = undefined;
	@Input({ required: true }) disabled!: boolean;
	@Input() currentSelection: string | null = null;
	@Input() syncedDate: Date | undefined;

	private _currentlyDisplayedMonth: Date = new Date();

	@Input() set currentlyDisplayedMonth(value: Date | null) {
		this._currentlyDisplayedMonth = value ? value : new Date();
		this.setDays();
	}

	get currentlyDisplayedMonth(): Date {
		return this._currentlyDisplayedMonth;
	}

	@Output() dateSelectionChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() monthChange: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	days: DatepickerDate[] = [];
	calendarWeeks: CalendarWeek[] = [];
	weekDays: string[] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
	currentlyHovered: DatepickerDate | null = null;
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	private setDays(): void {
		this.days = this.generateMonth(
			this.currentlyDisplayedMonth.getFullYear(),
			this.currentlyDisplayedMonth.getMonth() + 1
		);
		this.calendarWeeks = this.groupByCalendarWeek(this.days);
		this.cdRef.markForCheck();
	}

	/**
	 * Generates the days for a specific month
	 * Takes care of the offset for the first day of the month
	 * and the days of the previous and next month
	 * @param year
	 * @param month
	 * @private
	 */
	private generateMonth(year: number, month: number): DatepickerDate[] {
		const numberOfDays: number = new Date(year, month, 0).getDate();
		const firstDay: number = new Date(year, month - 1, 1).getDay();
		const offset = firstDay === 0 ? 6 : firstDay - 1;

		const previousMonthDays: number = (7 + offset) % 7;

		const nextMonthDays: number = (7 - new Date(year, month - 1, numberOfDays).getDay()) % 7;

		const previousMonth: number = month === 1 ? 12 : month - 1;
		const previousYear: number = month === 1 ? year - 1 : year;
		const nextMonth: number = month === 12 ? 1 : month + 1;
		const nextYear: number = month === 12 ? year + 1 : year;

		let monthDays: DatepickerDate[] = this.generatePreviousMonth(
			previousMonthDays,
			previousYear,
			previousMonth,
			year,
			month
		);

		// Generate current month days without UTC
		for (let i: number = 1; i <= numberOfDays; i++) {
			const day: Date = new Date(year, month - 1, i);
			monthDays.push({ date: dateToString(day), disabled: false });
		}

		// Generate next month days without UTC
		for (let i: number = 1; i <= nextMonthDays; i++) {
			const day: Date = new Date(nextYear, nextMonth - 1, i);
			monthDays.push({
				date: dateToString(day),
				notInActiveMonth: true,
			});
		}

		return this.disableDays(monthDays);
	}

	private generatePreviousMonth(
		previousMonthDays: number,
		previousYear: number,
		previousMonth: number,
		year: number,
		month: number
	): DatepickerDate[] {
		let monthDays: DatepickerDate[] = [];

		const lastDayOfPreviousMonth = new Date(year, month - 1, 0).getDate();

		for (let i = 0; i < previousMonthDays; i++) {
			const day: Date = new Date(
				previousYear,
				previousMonth - 1,
				lastDayOfPreviousMonth - previousMonthDays + i + 1
			);
			monthDays.push({
				date: dateToString(day),
				notInActiveMonth: true,
			});
		}
		return monthDays;
	}

	private disableDays(days: DatepickerDate[]): DatepickerDate[] {
		return days.map((day: DatepickerDate) => {
			const currentDay = new Date(day.date);

			if (this.minDate && currentDay < this.minDate) {
				day.disabled = true;
			}
			if (this.maxDate && currentDay > this.maxDate) {
				day.disabled = true;
			}
			if (this.disabled) {
				day.disabled = true;
			}

			return day;
		});
	}

	isDateSelected(day: DatepickerDate): boolean {
		if (day.date === this.currentSelection) {
			return true;
		}
		if (this.syncedDate) {
			function resetTime(date: Date | string): Date {
				const tempDate = new Date(date);
				return new Date(tempDate.setHours(0, 0, 0, 0));
			}

			const syncedDateTime = resetTime(this.syncedDate).getTime();
			const dayTimestamp = resetTime(convertToUTCDate(day.date)).getTime();

			return dayTimestamp === syncedDateTime;
		}
		return false;
	}

	isInRange(day: DatepickerDate): boolean {
		if (!this.currentSelection || day.disabled || !this.syncedDate) {
			return false;
		}
		const dayTimestamp = convertToUTCDate(new Date(day.date)).getTime();
		const syncedDateTimestamp = new Date(this.syncedDate).getTime();
		const selectedDayTimestamp = new Date(this.currentSelection).getTime();
		const startTime = Math.min(syncedDateTimestamp, selectedDayTimestamp);
		const endTime = Math.max(syncedDateTimestamp, selectedDayTimestamp);

		return dayTimestamp >= startTime && dayTimestamp <= endTime;
	}

	selectDay(day: DatepickerDate) {
		if (day.disabled || this.disabled) {
			return;
		}
		if (day.notInActiveMonth) {
			this.monthChange.emit(convertToUTCDate(day.date));
			return;
		}
		this.dateSelectionChanged.emit(day.date);
	}

	hoverDay(day: DatepickerDate) {
		if (!day.disabled && !this.disabled) {
			this.currentlyHovered = day;
		}
	}

	unhoverDay() {
		this.currentlyHovered = null;
	}

	private groupByCalendarWeek(days: DatepickerDate[]): CalendarWeek[] {
		const weekMap = new Map<number, CalendarWeek>();

		days.forEach((day: DatepickerDate) => {
			const date = new Date(day.date);
			const week = this.getISOWeekNumber(date);
			if (!weekMap.has(week)) {
				weekMap.set(week, { days: [], weekNumber: week });
			}
			weekMap.get(week)?.days.push(day);
		});
		return [...weekMap.values()];
	}

	public onCalendarWeekClicked(week: CalendarWeek): void {
		// only select the days that are not disabled
		// if all days are disabled we do nothing
		const days = week.days.filter((day) => !day.disabled);
		if (!days.length) {
			return;
		}

		this.calendarWeekClicked.emit({
			start: days[0].date,
			end: days[days.length - 1].date,
		});
	}

	getISOWeekNumber(date: Date): number {
		const target = new Date(date.valueOf());
		const dayNum = (target.getUTCDay() + 6) % 7; // adjust to get Monday as the first day of the week
		target.setUTCDate(target.getUTCDate() + 4 - dayNum); // set to nearest Thursday
		const yearStart = new Date(Date.UTC(target.getUTCFullYear(), 0, 1));
		return Math.ceil(((target.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
	}
}
