<div class="flex flex-wrap" data-cy="c-breadcrumb">
	@for (
		breadcrumb of breadcrumbs | breadcrumbEllipsis: _maxBreadcrumbs : expanded;
		track breadcrumb;
		let last = $last, first = $first
	) {
		<div
			class="flex items-center text-secondary-600 cursor-pointer hover:text-secondary-800"
			[ngClass]="{ 'text-secondary-800 cursor-default': last }"
			data-cy="c-breadcrumb-item"
		>
			<div class="flex items-center" (click)="doRoute(breadcrumb)" data-cy="c-breadcrumb-link">
				@if (first) {
					<ui-icon [icon]="icon" size="xs" data-cy="c-breadcrumb-icon"></ui-icon>
				} @else {
					<ui-icon icon="x_arrow_right" size="xs" data-cy="c-breadcrumb-divider"></ui-icon>
				}
				@if (breadcrumb.label) {
					<span class="text-xs font-semibold mx-1 duration-300">{{
						breadcrumb.label | translate
					}}</span>
				} @else {
					<span (click)="expanded = true" data-cy="c-breadcrumb-ellipsis">{{ breadcrumb }}</span>
				}
			</div>
		</div>
	}
</div>
