import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import { Tab } from '@agilox/ui-common';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'ui-tabs',
	standalone: true,
	imports: [NgClass, TranslateModule],
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
	router: Router = inject(Router);

	/** all tabs */
	@Input() tabs: Array<Tab> = [];

	/** emitted if active tab has changed */
	@Output() changedTab: EventEmitter<number> = new EventEmitter<number>();

	@Input() selectedIndex: number = 0;

	/**
	 * gets invoked when mat tab changed
	 * @param index
	 */
	tabChanged(index: number) {
		if (this.selectedIndex === index || this.tabs[index].disabled) {
			return;
		}
		if (this.tabs[index].url) {
			this.router.navigate([this.tabs[index].url]);
		} else {
			this.selectedIndex = index;
			this.changedTab.emit(index);
		}
	}
}
