<div class="flex items-center" data-cy="ui-toggleable-filter-button">
	<div
		class="flex items-center text-sm rounded-xl border max-h-6 justify-center pointer gap-0.5 m-0.5 ml-0 px-2 py-1 transition cursor-pointer"
		[ngClass]="
			isToggled
				? 'border-primary-400 hover:border-primary-800 text-primary-800'
				: 'border-secondary-400 hover:border-secondary-800'
		"
		(click)="toggle()"
	>
		@if (isToggled) {
			<ui-icon icon="x_check" type="primary" [action]="true"></ui-icon>
		}

		{{ text }}
	</div>
</div>
