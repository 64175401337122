import { MenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { DividerComponent } from '../divider/divider.component';

@Component({
	selector: 'ui-mobile-sub-nav-list-entry',
	standalone: true,
	imports: [NgIf, IconModule, TranslateModule, NgForOf, DividerComponent],
	templateUrl: './mobile-sub-nav-list-entry.component.html',
	styleUrls: ['./mobile-sub-nav-list-entry.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSubNavListEntryComponent {
	@Input() item: MenuPoint | undefined;
	@Input() isSubmenuOpen: boolean = false;
	@Input() currentUrl: string = '';
	@Input() navColor: NavColor = NavColors.Dark;

	@Output() clicked: EventEmitter<MenuPoint> = new EventEmitter<MenuPoint>();
}
