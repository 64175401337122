import { Meta, Response, Role, Vehicle } from '@agilox/common';
import {
	DropdownDirective,
	DropdownModule,
	IconModule,
	InputModule,
	NotifyComponent,
	PillComponent,
	SelectModule,
	SpinnerComponent,
	TooltipModule,
} from '@agilox/ui';
import { VehicleSelectOptionGroup } from '@agilox/ui-common';
import { AsyncPipe, NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	forwardRef,
	inject,
	Input,
	OnInit,
	signal,
	ViewChild,
	WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	tap,
} from 'rxjs';
import { InputComponent } from '../input/input.component';
import { UnionSelectOptionComponent } from '../union-select-option/union-select-option.component';
import { VehicleSelectOptionComponent } from '../vehicle-select-option/vehicle-select-option.component';
import { VehicleSelectPipe } from './pipes/vehicle-select.pipe';
import { VehiclesSelectService } from './vehicles-select.service';

@Component({
	selector: 'ui-vehicles-select',
	templateUrl: './vehicles-select.component.html',
	standalone: true,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VehiclesSelectComponent),
			multi: true,
		},
		VehiclesSelectService,
	],
	imports: [
		SelectModule,
		TranslateModule,
		AsyncPipe,
		VehicleSelectPipe,
		ReactiveFormsModule,
		DropdownModule,
		NgClass,
		PillComponent,
		IconModule,
		UnionSelectOptionComponent,
		VehicleSelectOptionComponent,
		NotifyComponent,
		InputModule,
		IconModule,
		TooltipModule,
		SpinnerComponent,
	],
})
export class VehiclesSelectComponent implements ControlValueAccessor, OnInit {
	private service: VehiclesSelectService = inject(VehiclesSelectService);
	private destroyRef: DestroyRef = inject(DestroyRef);
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() selectedSerials: string[] = [];
	@Input() maxSelections: number = 20;
	@Input() fullDropdownWidth: boolean = true;

	/**
	 * Temporary, needed to determine which details to show in the vehicle select
	 * Should be handled by injecting the auth service and getting the user role from there
	 * This is not possible at the moment, because every app has its own auth service
	 *
	 * We set it to default to support, because then we do not need to add the
	 * role everywhere we use the vehicle select
	 */
	@Input() role: Role = Role.support;

	@ViewChild('optionsList') optionsList: ElementRef<HTMLElement> | undefined;
	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;
	@ViewChild('searchInput') searchInput: InputComponent | undefined;

	/**
	 * Need to cache the selectedVehicles on the initial write
	 * otherwise not all vehicles will be selected.
	 */
	private _selectedVehicles: BehaviorSubject<Vehicle[]> = new BehaviorSubject<Vehicle[]>([]);
	selectedVehicles$: Observable<Vehicle[]> = this._selectedVehicles.asObservable();

	get selectedVehicles(): Vehicle[] {
		return this._selectedVehicles.value || [];
	}

	public loading: WritableSignal<boolean> = signal(false);

	private page: Meta = { number: 0, size: 50 };
	private currentPage: number = 1;

	private paginationSubject: Subject<Meta> = new Subject<Meta>();
	private pagination$: Observable<Meta> = this.paginationSubject.asObservable().pipe(
		startWith({
			number: 0,
			size: 50,
		})
	);

	public dropdownOpen: boolean = false;

	public formControl: FormControl = new FormControl({ value: [], disabled: true });

	public searchFormControl: FormControl = new FormControl();

	private searchObservable$: Observable<string> = this.searchFormControl.valueChanges.pipe(
		startWith(''),
		takeUntilDestroyed(),
		debounceTime(300),
		distinctUntilChanged(),
		// only set the loading when searched is triggered, not when scrolling
		tap(() => this.loading.set(true))
	);

	private _initialLoad: boolean = true;

	private _selectedUnions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

	private selectedUnionsDistinctFn = (previous: string[], current: string[]) => {
		/**
		 * It can be marked as distinct in the following cases:
		 * length is the same and all values are the same
		 *
		 * previous is longer than current and current is a subset of previous
		 */
		if (previous.length === current.length) {
			return previous.every((value, index) => value === current[index]);
		}
		/** if previous is longer than current and current is a subset of previous **/
		if (previous.length > current.length) {
			return current.every((value) => previous.includes(value));
		}
		return false;
	};

	/**
	 * This needs to emit the selected unions.
	 * But should not emit
	 * @private
	 */
	private selectedUnions$: Observable<string[]> = this._selectedUnions.asObservable().pipe(
		filter((unions) => !!unions && unions.length > 0),
		distinctUntilChanged(this.selectedUnionsDistinctFn),
		startWith([]),
		tap(() => this.loading.set(true))
	);

	get selectedUnions(): string[] {
		return this._selectedUnions.value ?? [];
	}

	/**
	 * If a user selects "Select all from union"
	 * We need to fetch all the vehicles from the union
	 * before we can select them
	 * @private
	 */
	private cachedUnionToSelect: string | undefined;

	/**
	 * Because we internally disable/enable the form control
	 * based on the loading state, we need to keep track of the
	 * disabled state from the parent
	 * @private
	 */
	private _disabledFromParent: boolean = false;

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnInit() {
		this.selectedVehicles$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((vehicles) => !!vehicles)
			)
			.subscribe((vehicles: Vehicle[]) => {
				this.setSelectedUnions(vehicles);
			});
	}

	private setSelectedUnions(vehicles: Vehicle[]) {
		const selectedUnions = vehicles.map((vehicle) => vehicle.unionUuid);
		this._selectedUnions.next(Array.from(new Set(selectedUnions)));
	}

	public isUnionSelected(union: VehicleSelectOptionGroup): boolean {
		return this.selectedUnions.some((selectedUnion) => selectedUnion === union.uuid);
	}

	public isVehicleSelected(vehicle: Vehicle | undefined): boolean {
		return !!this.selectedVehicles?.find((v: Vehicle) => v.serial === vehicle?.serial);
	}

	public onSelect(vehicle: Vehicle) {
		const alreadySelected = this.selectedVehicles?.find(
			(v: Vehicle) => v.serial === vehicle.serial
		);
		if (alreadySelected) {
			this._selectedVehicles.next(
				this.selectedVehicles.filter((v: Vehicle) => v.serial !== vehicle.serial)
			);
			return;
		}

		this._selectedVehicles.next([...this.selectedVehicles, vehicle]);
	}

	private nextPage() {
		this.currentPage++;
		this.page.size = 50 * this.currentPage;
		if (this.page.size < (this.page?.total || 0)) {
			this.paginationSubject.next(this.page);
		}
	}

	public onDropdownStateChange(open: boolean) {
		this.dropdownOpen = open;
		if (open) {
			this.searchFormControl.setValue('');
			this._selectedVehicles.next(this.formControl.value || []);
			this.focusSearchInput();
		}
		this.setSelectedUnions(this.selectedVehicles);
	}

	public onDeselectAll() {
		this._selectedVehicles.next([]);
	}

	public onDeselectAllInUnion(uuid: string) {
		this._selectedVehicles.next(
			this.selectedVehicles.filter((vehicle) => vehicle.unionUuid !== uuid)
		);
	}

	public onSelectAllInUnion(uuid: string) {
		/**
		 * Fetch all the vehicles from the union
		 * then select them
		 */
		this.cachedUnionToSelect = uuid;
		this._selectedUnions.next([...this.selectedUnions, uuid]);

		// clear the search
		this.searchFormControl.setValue('');
	}

	public onToggleUnion(union: VehicleSelectOptionGroup) {
		union.collapsed = !union.collapsed;
	}

	public vehicleResponse$: Observable<Vehicle[]> = combineLatest([
		this.searchObservable$,
		this.pagination$,
		this.selectedUnions$,
	]).pipe(
		debounceTime(300),
		tap(() => {
			this.formControl.disable({ emitEvent: false });
		}),
		switchMap(([search, page, unions]) =>
			this.service
				.fetchVehicles(search, page, this.selectedSerials, unions)
				.pipe(map((response) => this.sortOptions(response)))
		),
		tap((data) => {
			this.page = data.meta;

			if (!this._disabledFromParent) {
				this.formControl.enable({ emitEvent: false });
			}

			if (this._initialLoad) {
				this._initialLoad = false;
				this.setVehiclesToSelectedVehiclesFromSerialStrings(this.selectedSerials, data.data);
			} else if (this.cachedUnionToSelect) {
				this.setVehiclesFromCachedUnion(data.data);
				this.loading.set(false);
			}
			this.loading.set(false);
			this.cdRef.markForCheck();
		}),
		map((data) => data.data)
	);

	private setVehiclesFromCachedUnion(vehicles: Vehicle[]) {
		const vehiclesFromUnion = vehicles.filter(
			(vehicle) =>
				vehicle.unionUuid === this.cachedUnionToSelect && !this.isVehicleSelected(vehicle)
		);

		this._selectedVehicles.next([...this.selectedVehicles, ...vehiclesFromUnion]);
		this.cachedUnionToSelect = undefined;
	}

	writeValue(obj: Vehicle[]): void {
		this._selectedVehicles.next(obj);
		this.formControl.setValue(obj, { emitEvent: false });
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });

		this._disabledFromParent = isDisabled;
	}

	onScroll(): void {
		if (this.optionsList) {
			const element = this.optionsList.nativeElement;
			const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
			if (atBottom) {
				this.nextPage();
			}
		}
	}

	/**
	 * Needed in cases where the selected vehicles are passed as serials
	 * @example
	 * We get 123481723,1230129384
	 * When we receive the vehicles from the backend, we need to set the vehicles that have the serials 123481723 and 1230129384
	 * to the form control
	 *
	 * @param serials
	 * @param vehicles
	 * @private
	 */
	private setVehiclesToSelectedVehiclesFromSerialStrings(
		serials: string[],
		vehicles: Vehicle[]
	): void {
		if (serials?.length && vehicles?.length) {
			const selectedVehicles = vehicles.filter((vehicle) => serials.includes(vehicle.serial));
			this.formControl.setValue(selectedVehicles, { emitEvent: false });
			this._selectedVehicles.next(selectedVehicles);
		}
	}

	onSave() {
		if (this.loading()) {
			return;
		}

		if (this.selectedVehicles.length < this.maxSelections && this.selectedVehicles.length > 0) {
			// catch any null / undefined values
			let vehicles = this.selectedVehicles || [];
			this.formControl.setValue(vehicles, { emitEvent: false });
			this.onChange(vehicles);
			this.dropdown?.closeDropdown();
		}
	}

	public toggleDropdown() {
		this.dropdown?.toggleDropdown();
	}

	private sortOptions(response: Response<Vehicle>): Response<Vehicle> {
		const selectedSerials = this.selectedVehicles?.map((v: Vehicle) => v.serial) || [];
		const selectedVehicles = response.data.filter((vehicle) =>
			selectedSerials.includes(vehicle.serial)
		);
		const unselectedVehicles = response.data.filter(
			(vehicle) => !selectedSerials.includes(vehicle.serial)
		);
		return {
			...response,
			data: [...selectedVehicles, ...unselectedVehicles],
		};
	}

	/**
	 * Opening the dropdown takes one tick,
	 * so we need to wait for the dropdown to be open
	 */
	focusSearchInput() {
		setTimeout(() => {
			this.searchInput?.setFocus();
		});
	}
}
